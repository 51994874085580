import React from 'react';
import Aside from '../../components/Aside';
import Blockquote from '../../components/Blockquote';
import DetailReqAcdt from '../../components/DetailReqAcdt';
import BusinessRiskForm from '../../components/form-component/HomeBusinessRisksForm';

const MotorVehicleCover = () => {
    return(
        <div>
            <div classNameName=" d-flex align-items-center "
                data-secondary-overlay="8">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className=" text-center heroslider-content">
                                    <h2>MOTOR VEHICLE COVERS</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        <div className="container">
        <div className=" row ">
        <div className="col-lg-8 pr-0">
            <div class="service-details-content">
                <ol type="A" class="car-insurance mt-50">
                    <li>
                        <h5>THIRD PARTY</h5>
                        <ul>
                            <li>
                                <h6>What is covered:</h6>
                                Payment of legal compensation to the other person(s) who is killed, 
                                injured or whose property has been damaged by the use of your vehicle. 
                                This is the minimum cover required by law
                            </li>
                            <li>
                                <h6>What can be insured:</h6>
                                Cars, Trucks, Buses, Motorcyles, Articulated Vehicles. Any mechanically 
                                propelled machine use on the road.
                            </li>
                            <li>
                                <h6>Details Required:</h6>
                                <ol type="i">
                                    <li>Owners Name as shown on the Vehicle Licence(s)</li>
                                    <li>Make and Model of Vehicle(s)</li>
                                    <li>Registration Number of each Vehicle(s)</li>
                                    <li>Purpose for which the vehicle(s) is/are used (Private or Reward)</li>
                                </ol>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h5>THIRD PARTY, FIRE & THEFT</h5>
                        <ul>
                            <li>
                                <h6>What is covered:</h6>
                                <ol type="i">
                                    Payment compensation to :
                                    <li>the other person who is killed, injured or whose 
                                        property has been damaged by the use of your vehicle. 
                                        In addition, this cover will pay the owner for loss or 
                                        damage to the insured vehicle arising from
                                    </li>
                                    <li>Fire &</li>
                                    <li>Theft</li>
                                </ol>
                            </li>
                            <li>
                                <h6>What can be insured:</h6>
                                Cars, Trucks, Buses, Motorcyles, Articulated Vehicles. 
                                Any mechanically propelled machine use on the road.
                            </li>
                            <DetailReqAcdt />
                        </ul>
                    </li>

                    <li>
                        <h5> COMPREHENSIVE</h5>
                        <ul>
                            <li>
                                <h6>What is covered:</h6>
                                <ol type="i">
                                    Payment compensation to :
                                    <li>The other person who is killed, injured or whose property 
                                        has been damaged by the use of your vehicle. In addition 
                                        this cover will pay the owner for loss or damage to the 
                                        insured vehicle arising from
                                    </li>
                                    <li>Accidental collision of the vehicle as well as </li>
                                    <li>Fire damages caused to the vehicle or</li>
                                    <li>when the vehicle is stolen. Other benefits are also provided in addition to the noted key benefits.</li>
                                </ol>
                            </li>
                            <li>
                                <h6>What can be insured:</h6>
                                Cars, Trucks, Buses, Motorcyles, Articulated Vehicles. 
                                Any mechanically propelled machine use on the road.
                            </li>
                            <DetailReqAcdt />
                        </ul>
                    </li>
                </ol>
            </div>
        </div>
        <div className="col-lg-3 pl-0 offset-lg-1">
            <Aside className="section-padding-bottom-xs"/>
        </div>
        </div>
            <Blockquote />
            
            <BusinessRiskForm />

        </div>
        </div>
    )
}

export default MotorVehicleCover;