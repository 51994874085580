import React from 'react';
import { Link } from 'react-router-dom';

const WelcomeHeader = () => {
	return(
		<div class="heroslider d-flex align-items-center bg-image-1"
			data-secondary-overlay="8">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-lg-10">
						<div class="heroslider-content text-center">
							<h1>Smoothway <span>Insurance Brokers Limited</span></h1>
							<p>We Take Care Of All Your Insurance Needs</p>
							<div class="heroslider-buttonholder">
								<Link to="/contact" class="in-button in-button-colorwhite">Contact Us</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default WelcomeHeader;