import React from 'react';
import Aside from '../../components/Aside';
import Blockquote from '../../components/Blockquote';
import BusinessRiskForm from '../../components/form-component/HomeBusinessRisksForm';

const Obligatory = () => {
    return(
        <div>
            <div classNameName=" d-flex align-items-center "
                data-secondary-overlay="8">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className=" text-center heroslider-content">
                                    <h2>OBLIGATORY/CONTRACTUAL RISKS</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        <div className="container">
        <div className=" row ">
        <div className="col-lg-8 pr-0">
            <div class="service-details-content">
                <ol type="A" class="car-insurance mt-50">
                    <li>
                        <h5>EMPLOYERS LIABILITY</h5>
                        <ul>
                            <li>
                                <h6>What is covered:</h6>
                                Payment of statutorily specified (Employers' Liability Act) benefits following death, diseases contacted, incapacitation and certain expenses to the insured persons caused by accident, and diseases contacted in course of employment.
                            </li>
                            <li>
                                <h6>Who can be insured:</h6>
                                All category of employees employed by an employer of labour (Applicable to employers of above 5 employees)
                            </li>
                            <li>
                                <h6>Details Required:</h6>
                                Categorization of the employees according to their occupation and the related estimated total earning per category.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h5>CONTRACTOR/ERECTION ALL RISKS</h5>
                        <ul>
                            <li>
                                <h6>What is covered:</h6>
                                <ol type="i">
                                    <li>  Payment of compensation for loss or damage to contract /construction work; the contractor’s equipment/machines (whilst in transit to and from the camp site and in the camp site) and the general third party liability to the public in the course of discharging the contract/construction work.
                                    </li>
                                    <li>Fire &</li>
                                    <li>Theft</li>
                                </ol>
                            </li>
                            <li>
                                <h6>What can be insured:</h6>
                                The Project Works, the Contractors Equipment, tools and the general third party liability that may arise in the course of the construction or erection work.
                            </li>
                            <li>
                                <h6>Detail Required</h6>
                                <ol type="i">
                                    <li>Occupation of the insured company.</li>
                                    <li>Probable Minimum Limit for General Third Party Liability</li>
                                    <li>Probable Total/Minimum Equipment Value exposed at a time</li>
                                    <li>Probable Aggregate/Maximum Contract Value any one year</li>
                                </ol>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <h5>BONDS/GUARANTEES (Advance Payment, Performance, Credit)</h5>
                        <ul>
                            <li>
                                <h6>What is covered:</h6>
                                A surety/guarantee for the contract money advanced to the contractor and the assurance that contract work will be performed as per contract agreement as well as that credit advanced will be duly obliged.
                            </li>
                            <li>
                                <h6>Who can be insured:</h6>
                                Individuals and contractors for contract job awarded to them and as required by the principal/employer or the person advancing the credit facility.
                            </li>
                            <li>
                                <h6>Detail Required</h6>
                                <ol type="i">
                                    <li>Copy of the contract paper stipulating the bond requirements 
                                        and details of the contract.
                                    </li>
                                    <li>Company Seal and other documents on the corporate existence 
                                        of the company
                                    </li>
                                </ol>
                            </li>
                        </ul>
                    </li>
                </ol>
            </div>
        </div>
        <div className="col-lg-3 pl-0 offset-lg-1">
            <Aside className="section-padding-bottom-xs"/>
        </div>
        </div>
            <Blockquote />
            
            <BusinessRiskForm />

        </div>
        </div>
    )
}

export default Obligatory;