import React from 'react';

//  icons
import {FaEnvelope} from 'react-icons/fa';
import {FaPhoneAlt} from 'react-icons/fa';
import {FaWhatsapp} from 'react-icons/fa';


function HeaderTop() {
    return(
        <div>
            <header className="header">
            {/* Header Top Area */}
			<div className="header-toparea">
				<div className="container">
					<div className="row justify-content-betwween">
						<div className="col-lg-6">
							<ul className="header-topcontact">
								<li><i className="zmdi zmdi-phone"><FaPhoneAlt /></i> PHONE : <a href="tel:+234 704 282 3066">+234 704 282 3066</a></li>
								<li><i className="zmdi zmdi-phone"><FaWhatsapp /></i> WHATSAPP : <a href="Whatsapp:+234 815 914 5280">+234 815 914 5280</a></li>
							</ul>
						</div>
						<div className="col-lg-6">
							<ul className="header-toplinks">
							<li><i className="zmdi zmdi-email"><FaEnvelope /></i> E-MAIL : <a href="mailto:info@smoothwayinsurancebrokers.net">info@smoothwayinsurancebrokers.net</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			{/* Header Top Area */}

            </header>
        </div>
    )
};

export default HeaderTop;