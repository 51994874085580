import React from 'react';

const Clapform = () => {
    return(
        <div classNameName=" d-flex align-items-center "
        data-secondary-overlay="8">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className=" text-center heroslider-content">
                            <h2>CLAP FORM</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Clapform;