import React from 'react';
import { Link } from 'react-router-dom';
import {MdDoneAll} from 'react-icons/md';
import Aside from '../../components/Aside';

const Clap = () => {
    return(
        <div>
            <div classNameName=" d-flex align-items-center "
                data-secondary-overlay="8">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className=" text-center heroslider-content">
                                    <h2>COMPREHENSIVE LIFE ASSURANCE PLAN – CLAP</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

        <div className="container">
        <div className=" row ">
        <div className="col-lg-8 pr-0">
            <div class="service-details-content">
                <div class="car-insurance mt-50">
                        <p> Many are unaware of the usefulness of life assurance products. But obviously many people,
                            nowadays, are conversant with the desired benefits of investment for future needs.
                            But yet no one can be too sure that the future is certain to ensure realization of the
                            desired benefits of investment dreams.In recognition of this fact, SMOOTHWAY INSURANCE BROKERS LTD
                            [duly registered professional insurance Brokers by National Insurance Commission (Naicom) and a
                            member of the Nigerian Council of Registered Insurance Brokers (NCRIB)] is pleased to introduce to
                            you this unique insurance product named CLAP. It is designed to meet and/or
                            compliment your future dreams should the unexpected happens.
                        </p>
                </div>

                <blockquote class="service-blockquote mt-50">
                    <span class="top-overlay"></span>
                    <span class="bottom-overlay"></span>
                    <h4>CLAP IS FOR WHO?</h4>
                    <p>All young men and women between the ages of  18 and 55 years. It will cater for their
                            family needs in the event of an untimely death of that man or woman or following a
                        fatalaccident needing hospitalization of the person insured.
                    </p>
                </blockquote>

                <div class="car-insurance-area mt-50">
                    <h4 class="insurance-title">WHAT IS CLAP ?</h4>
                    <h6>Clap is a combination of insurance products that will pay you or your dependants:</h6>

                    <div class="row">
                        <div class="col-lg-4">
                            <div class="in-service-3 mt-30">
                                <ul>
                                    <li>
                                        <p>A Lump Sum Benefit (Multiple of ₦250,000) in the event of untimely death.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="in-service-3 mt-30">
                                 <ul>
                                    <li>
                                        <p>A Lump Sum (Multiple of ₦250,000) in the event of an accident leading to Permanent Disability.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="in-service-3 mt-30">
                                <ul>
                                    <li>
                                        <p>A Weekly Sum (Multiple of ₦5,000 weekly) in the event of temporary disability following an accident.
                                            This will be payable for 26 weeks</p>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="in-service-3 mt-30">
                                <ul>
                                    <li>
                                        <p>The Hospital Bill (in Multiple of N50,000) in the event of any form of accident leading to</p>
                                    </li>
                                 </ul>
                            </div>
                        </div>
                                        
                        <div class="col-lg-4">
                            <div class="in-service-3 mt-30">
                                <ul>
                                    <li>
                                        <p>The Burial Bill (in Multiple of N50,000) in the of event death and consequent burial expenses</p>
                                    </li>
                                </ul>
                            </div>
                        </div> 
                    </div>
                </div>
                <div class="car-insurance mt-50">
                    <h4>WHY CLAP ?</h4>
                    <ol class="ul-style-2">
                        <li><MdDoneAll className="done-all"/>It is comparatively cheap, affordable, and reliable. Just imagine yourself paying only N3,600 annually to secure a bunch of benefits comprising ₦250,000 Lump Sum payment to your beneficiary in the event of your untimely death or to you due to permanent disability and ₦5,000 weekly for 26 weeks in the event of temporary disability as well as ₦50,000 hospital bill following an accident and another ₦50,000 for burial expenses</li>
                        <li><MdDoneAll className="done-all"/>It is the easiest and cheapest means to create an estate of wealth for dependants should the unexpected happens.</li>
                        <li><MdDoneAll className="done-all"/>It is a silent and secured means to protect ones family from the hardship that may befall them in the event of the premature death of the breadwinner.</li>
                        <li><MdDoneAll className="done-all"/>The benefits can be reviewed from time to time, in line with the economic reality of the nation.</li>
                        <li><MdDoneAll className="done-all"/>Can be arranged and enjoyed very easily just by clicking few buttons at the convenience of your home.</li>
                    </ol>
                </div>

                <div>
                    <button>
                        <Link to="/clap-form">Click to fill form</Link>
                    </button>
                </div>
            </div>
        </div>
        <div className="col-lg-3 pl-0 offset-lg-1">
            <Aside className="section-padding-bottom-xs"/>
        </div>
        </div>
        </div>
        </div>
    )
}

export default Clap;