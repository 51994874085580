import React, { useState } from "react";

const Contact = () => {
  const [contactUs, setContactUs] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactUs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(contactUs)
  }
  return (
    <div>
      {/* <!-- Page Conttent --> */}
      <main class="page-content">
        <div
          classNameName=" d-flex align-items-center "
          data-secondary-overlay="8"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className=" text-center heroslider-content">
                  <h1>
                    <span>Contact</span>For a better future
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Services Details Area --> */}
        <div class="services-details-area in-section section-padding-lg">
          <div class="container">
            <div class="row">
              <div class="col-lg-8 pr-0">
                <div class="service-details-content">
                  <div class="car-insurance mt-50">
                    <h4>Contact Us</h4>
                    <p style={{ fontSize: "20px" }}>
                      Smoothway Insurance Brokers Limited has all it takes to
                      work for you and/or your organization. Please call or mail
                      us if you require further discussions. We look forward to
                      being of service to you and your organization and offer
                      you the assurance of our best service delivery always.
                    </p>
                  </div>

                  {/* <div class="car-insurance mt-50">
                                        <h4>Get the Right Coverage</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia </p>
                                    </div> */}

                  <div class="row">
                    <div class=" col-lg-12" style={{ paddingTop: "60px" }}>
                      {/* <!-- contact-form-warp Start --> */}
                      <div class="section-title text-center">
                        <h2>Send us your enquiries.</h2>
                        <h6>Thank You</h6>
                      </div>
                      <div class="contact-form-warp">
                        <form
                          id="contact-form"
                          action="https://demo.hasthemes.com/gregory-preview/mail.php"
                          method="post"
                        >
                          <div class="row">
                            <div class="col-lg-6 col-md-6">
                              <div class="input-box">
                                <input
                                  name="firstName"
                                  placeholder="First Name *"
                                  type="text"
                                  value={contactUs.firstName}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                              <div class="input-box">
                                <input
                                  name="lastName"
                                  placeholder="Last Name *"
                                  type="text"
                                  value={contactUs.lastName}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                              <div class="input-box">
                                <input
                                  name="email"
                                  placeholder="Email *"
                                  type="email"
                                  value={contactUs.email}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                              <div class="input-box">
                                <input
                                  name="phone"
                                  placeholder="Phone *"
                                  type="text"
                                  value={contactUs.phone}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div class="col-lg-12">
                              <div class="input-box">
                                <textarea
                                  name="message"
                                  placeholder="Your Message *"
                                  value={contactUs.message}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div class="contact-submit-btn text-center">
                            <button
                              type="submit"
                              class="submit-btn default-btn"
                              onClick={handleSubmit}
                            >
                              SUBMIT NOW
                            </button>
                            <p class="form-messege"></p>
                          </div>
                        </form>
                      </div>
                      {/* <!-- contact-form-warp End --> */}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 pl-0 offset-lg-1">
                <div>
                  <h4 class="service-widget-title">
                    <span>Corporate Address</span>
                  </h4>
                  <div style={{ paddingTop: "15px" }}>
                    <p>
                      Upper Rear Suite (Aiyede House),10, Abeokuta Str, Ebute –
                      Metta (East), P.O. Box 3647,Shomolu Lagos.
                      <br />
                      <strong>Tel:</strong> +234 704 282 3066 <br />
                      <strong>WhatsApp:</strong> +234 815 914 5280 <br />
                      <strong style={{ fontSize: "13px" }}>Email: </strong>
                      <span style={{ fontSize: "13px" }}>
                        info@smoothwayinsurancebrokers.net
                      </span>
                    </p>
                  </div>
                </div>
                <div>
                  <h4 class="service-widget-title">
                    <span>Abuja Address</span>
                  </h4>
                  <div style={{ paddingTop: "15px" }}>
                    <p>
                      Suite 2, ZM Plaza Beside GTB Plot 1469 Ahmadu Bello Way
                      Area 11 Garki 2 Abuja, Federal Capital Territory.
                      <br />
                      <strong>Tel:</strong> +234 704 282 3066 <br />
                      <strong>WhatsApp:</strong> +234 815 914 5280 <br />
                      <strong style={{ fontSize: "13px" }}>Email: </strong>
                      <span style={{ fontSize: "13px" }}>
                        info@smoothwayinsurancebrokers.net
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--// Services Details Area --> */}
      </main>
      {/* <!--// Page Conttent --> */}
    </div>
  );
};

export default Contact;
