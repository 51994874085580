import React from 'react';

const LBSLRRS = () => {
    const style ={
        fontSize: '23px',
        fontWeight: '400',
        color: '#0a3161'
    }

    return(
        <div>
            <div class="breadcrumb-area" data-bgimage="assets/images/bg/breadcrumb-bg-1.jpg" data-black-overlay="4">
                <div class="container">
                    <div class="in-breadcrumb">
                        <div class=" align-items-center">
                            <div className="text-center">
                                <h1 style={{color: 'white', fontSize: '20px'}}><span style={style}>Protected: </span> LBSL-RRS</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* // <!--// Breadcrumb --> */}

            <div class="contact-area in-section section-padding-lg">
                <div class="row justify-content-center">
                        <div class="col-lg-6">
                            <div class="section-title text-center">
                            <h6>This content is password protected. To view it please enter your password below</h6>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div class="col-lg-6 col-md-6">
                        <div class="input-box">
                            <input name="con_name" placeholder="Password *" type="password" />
                        </div>
                    </div>
                </div>
                <div class="contact-submit-btn text-center">
                    <button type="submit" class="submit-btn">ENTER</button>
                </div>
            </div>
        </div>
    )
}

export default LBSLRRS;