import React from 'react';
import Aside from '../../components/Aside';
import Blockquote from '../../components/Blockquote';
import BusinessRiskForm from '../../components/form-component/HomeBusinessRisksForm';

const AccidentLifeCovers = () => {
    return(
        <div>
            <div classNameName=" d-flex align-items-center "
                data-secondary-overlay="8">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className=" text-center heroslider-content">
                                    <h2>ACCIDENT/LIFE COVERS</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        <div className="container">
        <div className=" row ">
        <div className="col-lg-8 pr-0">
            <div class="service-details-content">
                <ol type="A" class="car-insurance mt-50">
                    <li>
                        <h5>LIFE ASSURANCE</h5>
                        <ul>
                            <li>
                                <h6>What is covered:</h6>
                                Payment of specified benefits following death of the insured
                                 persons caused by any means.
                            </li>
                            <li>
                                <h6>Who can be insured:</h6>
                                Individual, a group of persons such as members of a family or an 
                                organization like clubs and employees of a company. The Pension Reform 
                                Act of 2004 in Nigeria, made it compulsory for employers
                                 of more than 5 employees to have this cover in place as 
                                 part of the requirements of the new Pension Reform deal
                            </li>
                            <li>
                                <h6>Details Required:</h6>
                                <ol type="i">
                                    <li>Name of each Person (Employee) to be Insured</li>
                                    <li>Date of Birth of each person to be insured: DD/MM/YYY</li>
                                    <li>Desired Death Benefits (For Purpose of Pension = 3 x Total
                                         Annual Earning of each insured employee)</li>
                                    <li>Duration of Cover (For the purpose Pension = up to age 65 yrs)</li>
                                </ol>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h5>PERSONAL ACCIDENTS</h5>
                        <ul>
                            <li>
                                <h6>What is covered:</h6>
                                Payment of specified benefits following death, incapacitation and 
                                certain expenses due to the insured persons caused by accidental 
                                and violent means at anytime worldwide.
                            </li>
                            <li>
                                <h6>Who can be insured:</h6>
                                Individual, a group of individual such as members of a family 
                                or an organization like clubs and employees of a company.
                            </li>
                            <li>
                                <h6>Details Required: </h6>
                                <ol type="i">
                                    <li>Name(s) of Person (Employee(s) or Individual(s) to be Insured</li>
                                </ol>
                            </li>
                            <li>
                                <h6>Desired Benefits:</h6>
                                <ol type="i">
                                    <li>Death/Permanent Disability</li>
                                    <li>Temp Disability (Weekly for 104wks)</li>
                                    <li>Medical and</li>
                                    <li>Burial Expenses Annual Earning of insured persons (basic + transport + housing)
                                        Multiple of earnings for Death/PD: Annual limit for Medical/Burial Expenses
                                    </li>
                                </ol>
                            </li>
                        </ul>
                    </li>
                </ol>
            </div>
        </div>
        <div className="col-lg-3 pl-0 offset-lg-1">
            <Aside className="section-padding-bottom-xs"/>
        </div>
        </div>
            <Blockquote />
            
            <BusinessRiskForm />

        </div>
        </div>
    )
}

export default AccidentLifeCovers;