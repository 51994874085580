import React from 'react';
import './TeamMemberCard.css'

const TeamMemberCard = ({ image, name, title, content }) => {
  return (
    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
      <div className="card mt-4">
        <img src={image} className="card-img-top" alt={`${name}`} />
        <div className="card-body">
          <h5 className="card-title">{name}</h5>
          <h6 className="card-subtitle mb-2 text-muted">{title}</h6>
          <p className="card-text">{content}</p>
        </div>
      </div>
    </div>
  );
};

export default TeamMemberCard;
