import React from 'react';


import abt2 from '../assets/images/other/protect.jpg';

const AboutUs = () => {

    return(
        <div>
            <div className=" d-flex align-items-center"
			 data-secondary-overlay="8">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-10">
							<div className=" text-center heroslider-content">
								<h1>Smoothway<span>Insurance Brokers Limited</span></h1>
								<p>
									We do things a bit differently, and that's the way we like it!
                                </p>
							</div>
						</div>
					</div>
				</div>
			</div>

            <main className="page-content">
                {/* <!-- Features Area --> */}
                <div className="features-area in-section section-padding-md bg-white">
                    <div className="container">
                        <div className="row features-wrapper">
                            <div className="col-12">
								<p>
									At Smoothway Insurance Brokers Limited, we are professional 
									advisers/consultants on insurance matters. We handle most 
									expertly all classes of insurance.We have a culture of 
									creating values for our Customers. We are reputed for 
									providing qualitative Insurance and Risk Management 
									service to our clients in diverse areas of operation.
								</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!--// Features Area --> */}

                {/* <!-- About Area --> */}
			<div className="about-area in-section section-padding-top-lg bg-white" style={{paddingTop: '0'}}>
				<div className="container custom-container">
					<div className="row no-gutters">
						<div className="col-xl-6 col-lg-12">
							<div className="about-content heightmatch rd-padding">
								{/* <h6>ABOUT US </h6> */}
								{/* <h2>Something about us</h2> */}
								<h4>Our Mission Statement</h4>
								<p>
								To be an outstanding, innovative, and ethically guided
								business entity providing value based insurance services
								that will make us a key factor particularly in the Nigerian
								Economy and above all to provide consistent and uncompromising
								quality in all we do in order to satisfy the need of our
								clients at reasonable and affordable cost.
								</p>
							</div>
							<div className="about-content heightmatch rd-padding">
								<h4>Our Goal</h4>
								<p>
								We are aspiring to give absolute satisfaction to our carefully
								selected clients as to be able to establish an everlasting business
								relationship with them.
								</p>
							</div>
							<div className="about-content heightmatch rd-padding">
								<h4>Professional Statement</h4>
								<p>
									Our professional misconduct/negligence is protected by a Professional
									Indemnity Insurance Cover (with a limit of N100m anyone event)
									underwritten by Goldlink Insurance Plc.
								</p>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6">
							<div className="heightmatch">
								<div className="in-videobox">
									<img src={abt2} alt="man with umbrella" />
								</div>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6">
							<div className="counterbox heightmatch" data-bgimage="assets/images/other/counterbox-bg.jpg" data-secondary-overlay="9">
								<div className="counterbox-inner">
									<div className="counterbox-block">
										<div className="counterbox-blockinner">
											<h2><span className="counter">95</span>%</h2>
											<h6>Satisfaction</h6>
										</div>
									</div>
									<div className="counterbox-block">
										<div className="counterbox-blockinner">
										</div>
									</div>
									<div className="counterbox-block">
										<div className="counterbox-blockinner">
										</div>
									</div>
									<div className="counterbox-block">
										<div className="counterbox-blockinner">
											<h2><span className="counter">4000</span></h2>
											<h6>New Client</h6>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-6 col-lg-12 ">
							<div className="insurencebox heightmatch rd-padding">
								<h4>Request a free call back</h4>
								<h2>Have a business to protect?</h2>
								<form action="#" className="insurencebox-form">
									<select>
										<option value="life">Life Insurence</option>
										<option value="home">Home Insurence</option>
										<option value="travel">Travel Insurence</option>
										<option value="business">Business Insurence</option>
										<option value="car">Car Insurence</option>
										<option value="auto">Auto Insurence</option>
									</select>
									<input type="text" placeholder="Name *" />
									<input type="text" placeholder="Email *" />
									<input type="text" placeholder="Phone *" />
									<button type="submit" className="in-button">Submit</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <!--// About Area --> */}

            </main>
        </div>
    )
}

export default AboutUs;