import React from 'react';

const Blockquote = () => {
    return(
        <blockquote class="service-blockquote" style={{marginBottom: '60px'}}>
                <span class="top-overlay"></span>
                <span class="bottom-overlay"></span>
                <p>If you can please provide us with the  appropriate details required 
                    under each highlighted risk, we will promptly forward to you competitive 
                    premium quotes that will enable you appreciate the affordable cost of 
                    risk transfer mechanism that will make you take  appropriate decision 
                    on your insurable risks accordingly.
                </p>
        </blockquote>
    )
}

export default Blockquote;