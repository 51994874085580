import React from 'react';
import { Link } from 'react-router-dom';

// icons
import {FaFacebook} from 'react-icons/fa';
import {FaTwitter} from 'react-icons/fa';
import {FaEnvelope} from 'react-icons/fa';
import {FaPhoneAlt} from 'react-icons/fa';
import {FaHome} from 'react-icons/fa';

import Logo from '../assets/images/smoothway_logo.jpg';
import PaperPlane from '../assets/images/icons/paper-plane-white.png';

function Footer(){
	const date = new Date();
	const year = date.getFullYear();
    return (
        <footer classNameName="footer">
            {/* <!-- Footer Contact Area --> */}
			<div className="footer-contact-area">
				<div className="container">
					<div className="footer-contact">
						<div className="row">
							<div className="col">
								<div className="footer-contact-block">
									<span className="footer-contact-icon">
										<i className="zmdi zmdi-phone"><FaPhoneAlt /></i>
									</span>
									<p><a href="tel:+2347042823066">+234 704 282 3066</a><br /><a href="tel:+2348159145280">+234 815 914 5280</a></p>
								</div>
							</div>
							<div className="col">
								<div className="footer-contact-block">
									<span className="footer-contact-icon">
										<i className="zmdi zmdi-home"><FaHome /></i>
									</span>
									<p>Upper Rear Suite (Aiyede House), 10, Abeokuta Str, Ebute – Metta (East), 
										P.O. Box 3647,Shomolu Lagos.
									</p>
								</div>
							</div>
							<div className="col">
								<div className="footer-contact-block">
									<span className="footer-contact-icon">
										<i className="zmdi zmdi-email"><FaEnvelope/></i>
									</span>
									<p><a href="mailto:info@smoothwayinsurancebrokers.net">info@smoothwayinsurancebrokers.net</a></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <!--// Footer Contact Area --> */}

            {/* <!-- Footer Inner --> */}
			<div className="footer-inner">

				{/* <!-- Footer Widgets Area --> */}
				<div className="footer-widgets-area section-padding-lg">
					<div className="container">
						<div className="row widgets footer-widgets">

							<div className="col-lg-3 col-md-6">
								<div className="single-widget widget-info">
									<div className="logo">
										<Link to="/">
											<img src={Logo} alt="footer logo"/>
										</Link>
									</div>
									<p>At Smoothway Insurance Brokers Limited,
									we are professional advisers/consultants on insurance matters.
									We handle most expertly all classes of insurance.
									</p>
									<ul className="footer-socialicons">
										<li><a href="#fb"><i className="zmdi zmdi-facebook"><FaFacebook/></i></a></li>
										<li><a href="#tw"><i className="zmdi zmdi-twitter"><FaTwitter/></i></a></li>
									</ul>
								</div>
							</div>

							<div className="col-lg-4 col-md-7">
								<div className="single-widget widget-latestblog">
								</div>
							</div>

							<div className="col-lg-4 col-md-6">
								<div className="single-widget widget-newsletter">
									<h4 className="widget-title">
										<span>Newsletter</span>
									</h4>
									<p>Sign up for our latest products</p>
									<form action="#" className="widget-newsletter-form">
										<input type="text" placeholder="Your email..."/>
										<button type="submit"><img src={ PaperPlane } alt="send"/></button>
									</form>
								</div>
							</div>

						</div>
					</div>
				</div>
				{/* <!--// Footer Widgets Area -->

				<!-- Footer Copyright Area --> */}
				<div className="footer-copyright-area">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-12 col-12">
								<p className="copyright-text" style={{textAlign: "center"}}>Copyright {year} &copy; <Link href="/about-us">Smoothway Insurance Broker Limited</Link>, All Right
									Reserved
								</p>
							</div>
						</div>
					</div>
				</div>
				{/* <!--// Footer Copyright Area --> */}

			</div>
			{/* <!--// Footer Inner --> */}

        </footer>
    );
};

export default Footer;