import React from 'react';
import { Link } from 'react-router-dom';

const Aside = () => {
    return(
        <div class="service-widget-categories">
            <h4 class="service-widget-title">
                <span>Categories</span>
            </h4>
            <ul class="service-cat-list">
                <li><Link to="/clap">COMPREHENSIVE LIFE ASSURANCE PLAN(CLAP)</Link></li>
                <li><Link to="/home-business-risk">Home/Bussiness Risk</Link></li>
                <li><Link to="/accident-life-covers">Accident/Life Covers</Link></li>
                <li><Link to="/motor-vehicle-covers">Motor Vehicle Covers</Link></li>
                <li><Link to="/legal-liability-covers">Legal Liability Covers</Link></li>
                <li><Link to="/obligatory-contractual-risk">Obligatory/Contractual Risks</Link></li>
                <li><Link to="/transit-risk">Transit Risks</Link></li>
            </ul>
        </div>
    )
}

export default Aside;