import React, { Component } from "react";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
// import Beneficiaries from "./Beneficiaries";
import BurialExpenses from "./BurialExpenses";
import DeathBenefits from "./DeathBenefits";
import HospitalBill from "./HospitalBill";
import PermanentDisability from "./PermanentDisability";
import TemporaryDisability from "./TemporaryDisabilty";

class DesiredBenefits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBenefit: "",
      beneficiaries: [
        { name: "", relationship: "" },
        { name: "", relationship: "" },
      ],
      commencementDate: "",
    };
  }

  selectBenefit = (benefit) => {
    this.setState({
      selectedBenefit: benefit,
    });
  };

  handleChange = (e, index, field) => {
    const beneficiaries = [...this.state.beneficiaries];
    beneficiaries[index][field] = e.target.value;
    this.setState({ beneficiaries });
  };

  handleDateChange = (e) => {
    this.setState({ commencementDate: e.target.value });
  };

  nextForm = (e) => {
    e.preventDefault();
    const { selectedBenefit, beneficiaries, commencementDate } = this.state;
    const data = {
      selectedBenefit,
      beneficiaries,
      commencementDate,
    };
    // Pass the current state to the parent component or perform any other logic here
    this.props.onSubmit(data);
    this.props.nextStep();
  };

  prevForm = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { selectedBenefit, beneficiaries, commencementDate } = this.state;
    return (
      <div>
        <h4 className="form-heading">Desired Benefits</h4>
        <div className="col-md-12" style={{ paddingBottom: "30px" }}>
          <div className="marital-container">
            <div>
              <label className="desired-group">
                <input
                  type="radio"
                  checked={selectedBenefit === "death"}
                  onChange={() => this.selectBenefit("death")}
                />{" "}
                Death
              </label>
            </div>
            <div>
              <label className="desired-group">
                <input
                  type="radio"
                  checked={selectedBenefit === "permanent"}
                  onChange={() => this.selectBenefit("permanent")}
                />{" "}
                Permanent Disability
              </label>
            </div>
            <div>
              <label className="desired-group">
                <input
                  type="radio"
                  checked={selectedBenefit === "temporary"}
                  onChange={() => this.selectBenefit("temporary")}
                />{" "}
                Temporary Disability
              </label>
            </div>
            <div>
              <label className="desired-group">
                <input
                  type="radio"
                  checked={selectedBenefit === "hospital"}
                  onChange={() => this.selectBenefit("hospital")}
                />{" "}
                Hospital Bill
              </label>
            </div>
            <div>
              <label className="desired-group">
                <input
                  type="radio"
                  checked={selectedBenefit === "burial"}
                  onChange={() => this.selectBenefit("burial")}
                />{" "}
                Burial Expenses
              </label>
            </div>
          </div>
          <div>
            {selectedBenefit === "death" && <DeathBenefits />}
            {selectedBenefit === "permanent" && <PermanentDisability />}
            {selectedBenefit === "temporary" && <TemporaryDisability />}
            {selectedBenefit === "hospital" && <HospitalBill />}
            {selectedBenefit === "burial" && <BurialExpenses />}
          </div>
          <div className="section-padding-top-xs">
            <h4>Name of Beneficiary(ies)</h4>
            <hr></hr>
            <div className="row space-top-bottom">
              <div className="col-sm-4">
                <label>Name (1)</label>
                <input
                  type="text"
                  value={beneficiaries[0].name}
                  onChange={(e) => this.handleChange(e, 0, "name")}
                  required
                />
              </div>
              <div className="col-sm-4">
                <label>Relationship (1)</label>
                <input
                  type="text"
                  value={beneficiaries[0].relationship}
                  onChange={(e) => this.handleChange(e, 0, "relationship")}
                  required
                />
              </div>
            </div>
            <div className="row space-top-bottom">
              <div className="col-sm-4">
                <label>Name (2)</label>
                <input
                  type="text"
                  value={beneficiaries[1].name}
                  onChange={(e) => this.handleChange(e, 1, "name")}
                  required
                />
              </div>
              <div className="col-sm-4">
                <label>Relationship (2)</label>
                <input
                  type="text"
                  value={beneficiaries[1].relationship}
                  onChange={(e) => this.handleChange(e, 1, "relationship")}
                  required
                />
              </div>
            </div>
            <div className="col-sm-4 space-top-bottom">
              <label>Commencement Date</label>
              <input
                type="date"
                value={commencementDate}
                onChange={this.handleDateChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-5">
            <button className="clap-next" onClick={this.nextForm}>
              Next <MdNavigateNext />
            </button>
          </div>
          <div className="col-sm-5">
            <button className="clap-next" onClick={this.prevForm}>
              <MdNavigateBefore /> Previous
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default DesiredBenefits;
