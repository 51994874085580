import React from 'react';
import {Link} from 'react-router-dom';
// import blg1 from '../assets/images/blog/blog-image-1.jpg';
// import blg2 from '../assets/images/blog/blog-image-2.jpg';
// import blg3 from '../assets/images/blog/blog-image-3.jpg';

const BlogArea = () => {
    return(
        <div class="blogs-area in-section section-padding-lg bg-white">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-6">
                        <div class="section-title text-center">
                            <h6>BEST SERVICES FOR YOU</h6>
                            <h2>Our News Feed</h2>
                        </div>
                    </div>
                </div>
                <div class="row blog-slider-active in-slidearrow">

                    {/* <!-- Single Blog --> */}
                    <div class="col-lg-4">
                        <div class="in-blog mt-30">
                            {/* <div class="in-blog-image">
                                <Link to="#">
                                    <img src={blg1} />
                                </Link>
                            </div> */}
                            <div class="in-blog-content">
                                <div class="in-blog-metatop">
                                    <span>December 19, 2019</span>
                                    {/* <span><Link to="#">Life Insurance</Link></span> */}
                                </div>
                                <h4 class="in-blog-title"><a href="#p">Mr Popoola Seun.</a></h4>
                                <p>Death: ₦ 500,000 Permanent Disability: Temporary Disability: Hospital Bill: ₦ 50,000 Burial:</p>
                            </div>
                        </div>
                    </div>
                    {/* <!--// Single Blog -->

                    <!-- Single Blog --> */}
                    <div class="col-lg-4">
                        <div class="in-blog mt-30">
                            {/* <div class="in-blog-image">
                                <Link to="#">
                                    <img src={blg2} />
                                </Link>
                            </div> */}
                            <div class="in-blog-content">
                                <div class="in-blog-metatop">
                                    <span>December 17, 2019</span>
                                    {/* <span><Link to="#">Life Insurance</Link></span> */}
                                </div>
                                <h4 class="in-blog-title"><Link to="#">Olatunde Samuel Ogunnoiki</Link></h4>
                                <p>Death: ₦ 500,000 Permanent Disability: ₦ 500,000 Temporary Disability: ₦ 10,000 Hospital Bill: Burial:</p>
                            </div>
                        </div>
                    </div>
                    {/* <!--// Single Blog -->

                    <!-- Single Blog --> */}
                    <div class="col-lg-4">
                        <div class="in-blog mt-30">
                            <div class="in-blog-content">
                                    <div class="in-blog-metatop">
                                        <span>December 16, 2019</span>
                                        {/* <span><Link to="#">Life Insurance</Link></span> */}
                                    </div>
                                    <h4 class="in-blog-title"><Link to="#">Dipo Peters</Link></h4>
                                    <p>Death: ₦ 500,000 Permanent Disability: Temporary Disability: Hospital Bill: Burial:</p>
                            </div>
                        </div>
                    </div>
                    {/* <!--// Single Blog --> */}
                </div>
            </div>
        </div>
    )
}

export default BlogArea;