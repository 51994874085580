import React, { Component } from "react";
import { MdNavigateNext } from "react-icons/md";

class PersonalData extends Component {
  onRadioClick = (e) => {
    this.props.handleChange(e);
  };
  constructor(props) {
    super(props);
    this.state = {
      title: "Mr.", // Default title
      firstName: "",
      middleName: "",
      lastName: "",
      maritalStatus: "", // Default marital status
      address: "",
      workPhone: "",
      email: "",
      mobilePhone: "",
      occupation: "",
      nationality: "",
      dateOfBirth: "",
      stateOfOrigin: "",
      placeOfBirth: "",
      bvnNumber: "",
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    // Prepare data object to pass back to MainForm
    const {
      title,
      firstName,
      middleName,
      lastName,
      maritalStatus,
      address,
      workPhone,
      email,
      mobilePhone,
      occupation,
      nationality,
      dateOfBirth,
      stateOfOrigin,
      placeOfBirth,
      bvnNumber,
    } = this.state;

    const data = {
      title,
      firstName,
      middleName,
      lastName,
      maritalStatus,
      address,
      workPhone,
      email,
      mobilePhone,
      occupation,
      nationality,
      dateOfBirth,
      stateOfOrigin,
      placeOfBirth,
      bvnNumber,
    };

    // Pass data back to parent component
    this.props.onSubmit(data);

    // Move to next step
    this.props.nextStep();
  };

  render() {
    const {
      title,
      firstName,
      middleName,
      lastName,
      maritalStatus,
      address,
      workPhone,
      email,
      mobilePhone,
      occupation,
      nationality,
      dateOfBirth,
      stateOfOrigin,
      placeOfBirth,
      bvnNumber,
    } = this.state;
    return (
      <div>
        <h4 className="form-heading">Personal Data</h4>
        <div className="col-md-12 mb-2" style={{ paddingBottom: "30px" }}>
          <label>Name</label>
          <div className="row">
            <select
              className="col-md-2 mg-xsm-rght mb-2"
              name="title"
              value={title}
              onChange={this.handleChange}
            >
              <option>Mr.</option>
              <option>Mrs.</option>
              <option>Miss</option>
              <option>Ms.</option>
              <option>Dr.</option>
              <option>Prof.</option>
              <option>Rev.</option>
            </select>
            <div style={{display:'flex', flexWrap:'wrap', gap:'10px'}}>
              <input
                type="text"
                className="col-md-3 mg-xsm-rght"
                placeholder="Firstname"
                name="firstName"
                value={firstName}
                onChange={this.handleChange}
              />
              <input
                type="text"
                className="col-md-3 mg-xsm-rght"
                placeholder="Middlename"
                name="middleName"
                value={middleName}
                onChange={this.handleChange}
              />
              <input
                type="text"
                className="col-md-3 mg-xsm-rght"
                placeholder="Lastname"
                name="lastName"
                value={lastName}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="marital-container">
            <label htmlFor="maritalStatus">Marital Status</label>
            <select name="maritalStatus" value={maritalStatus} onChange={this.handleChange}>
              <option value={''}>Select Marital status</option>
              {['married','single','divorced', 'widow'].map((x)=>(
                <option key={x} value={x}>{x.toUpperCase()}</option>
              ))}
            </select>
          </div>
          <div>
            <label>Address</label>
            <input
              type="text"
              value={address}
              name="address"
              onChange={this.handleChange}
              placeholder="10 Awodiya street, Ikeja, Lagos"
            />
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div>
                <label>Work Phone</label>
                <input
                  type="tel"
                  name="workPhone"
                  value={workPhone}
                  onChange={this.handleChange}
                />
              </div>
              <div>
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div>
                <label>Mobile Phone</label>
                <input
                  type="tel"
                  name="mobilePhone"
                  value={mobilePhone}
                  onChange={this.handleChange}
                />
              </div>
              <div>
                <label>Occupation</label>
                <input
                  type="text"
                  name="occupation"
                  value={occupation}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <div>
                <label>Nationality</label>
                <input
                  type="text"
                  name="nationality"
                  value={nationality}
                  onChange={this.handleChange}
                />
              </div>
              <div>
                <label>Date of Birth</label>
                <input
                  type="date"
                  name="dateOfBirth"
                  value={dateOfBirth}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div>
                <label>State of Origin</label>
                <input
                  type="text"
                  name="stateOfOrigin"
                  value={stateOfOrigin}
                  onChange={this.handleChange}
                />
              </div>
              <div>
                <label>Occupation</label>
                <input
                  type="text"
                  name="occupation"
                  value={occupation}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div>
                <label>Place of Birth</label>
                <input
                  type="text"
                  name="placeOfBirth"
                  value={placeOfBirth}
                  onChange={this.handleChange}
                />
              </div>
              <div>
                <label>BVN Number</label>
                <input
                  type="text"
                  name="bvnNumber"
                  value={bvnNumber}
                  onChange={this.handleChange}
                  required
                />
              </div>
            </div>
          </div>
        </div>

        <button className="clap-next" onClick={this.handleSubmit}>
          Next <MdNavigateNext />
        </button>
      </div>
    );
  }
}

export default PersonalData;
