import React from 'react'

const DetailRequired = () => {
    return(
        <li>
            <h6>Details Required:</h6>
            <ol type="i">
                <li>Location address of items to be insured</li>
                <li>Description (Make & Model) of items to be insured</li>
                <li>Current Market Value of items to be insured</li>
                <li>Date items insured is purchased</li>
            </ol>
        </li>
    )
}

export default DetailRequired;