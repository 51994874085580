import React from 'react';
// import {Link} from 'react-router-dom';

import BlogArea from '../components/BlogArea';
import Welcome from '../components/WelcomeHeader';

const Home = () => {
    return (
        <div>
			<Welcome />

            {/* <!-- Page Content --> */}
		    <main class="page-content">
            {/* <!-- Features Area --> */}
			{/* <div class="features-area in-section section-padding-md bg-white">
				<div class="container">
					<div class="row features-wrapper">
						<div class="col-lg-4 col-md-6 col-12">
							<div class="in-feature">
								<span class="in-feature-icon">
									<i class="flaticon-lock"></i>
								</span>
								<h4>Our Mission</h4>
								<p>To be an outstanding, innovative, and ethically guided business entity
									providing value based insurance services that will make us a key factor
									particularly in the Nigerian Economy.
								</p>
							</div>
						</div>
						<div class="col-lg-4 col-md-6 col-12">
							<div class="in-feature">
								<span class="in-feature-icon">
									<i class="flaticon-lab"></i>
								</span>
								<h4>Our Goal</h4>
								<p>We are aspiring to give absolute satisfaction to our carefully selected clients
									as to be able to establish an everlasting business relationship with them.</p>
							</div>
						</div>
						<div class="col-lg-4 col-md-6 col-12">
							<div class="in-feature">
								<span class="in-feature-icon">
									<i class="flaticon-partner"></i>
								</span>
								<h4>Professional Security</h4>
								<p>Our professional misconduct/negligence is protected by a Professional Indemnity
									Insurance Cover (with a limit of N100m anyone event) underwritten by Goldlink
									Insurance Plc.</p>
							</div>
						</div>
					</div>
				</div>
			</div> */}
			{/* <!--// Features Area -->

			<!-- Services Area --> */}
			<div class="">
			{/* <div class="services-area in-section section-padding-lg bg-shape"> */}
				<div class="container">
					{/* <div class="row justify-content-center">
						<div class="col-lg-6">
							<div class="section-title text-center">
								<h6>BEST SERVICES FOR YOU</h6>
								<h2>What We Provide</h2>
							</div>
						</div>
					</div> */}
					<div class="row">

						{/* <!-- Single Service --> */}
						{/* <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
							<div class="in-service mt-30">
								<span class="in-service-icon">
									<i class="flaticon-life-insurence"></i>
								</span>
								<h5>Risk Management</h5>
								<p>We offer professional risk survey of your operations in
									order to identify and evaluate all the risk exposure of your business and
									advise the appropriate mechanism  for prevention, elimination or transfer.</p>
								<span class="in-service-transparenticon">
									<i class="flaticon-life-insurence"></i>
								</span>
							</div>
						</div> */}
						{/* <!--// Single Service -->

						<!-- Single Service --> */}
						{/* <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
							<div class="in-service mt-30">
								<span class="in-service-icon">
									<i class="flaticon-mortgage"></i>
								</span>
								<h5>Risk Placement</h5>
								<p>We recommend and place your insurance covers cost effectively.</p>
								<span class="in-service-transparenticon">
									<i class="flaticon-mortgage"></i>
								</span>
							</div>
						</div> */}
						{/* <!--// Single Service -->

						<!-- Single Service --> */}
						{/* <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
							<div class="in-service mt-30">
								<span class="in-service-icon">
									<i class="flaticon-van"></i>
								</span>
								<h5>Risk Review</h5>
								<p>We regularly review your risks in line with the state of the economy.</p>
								<span class="in-service-transparenticon">
									<i class="flaticon-van"></i>
								</span>
							</div>
						</div> */}
						{/* <!--// Single Service -->

						<!-- Single Service --> */}
						{/* <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
							<div class="in-service mt-30">
								<span class="in-service-icon">
									<i class="flaticon-bars"></i>
								</span>
								<h5>Record Keeping / Interpretation</h5>
								<p>We keep adequate records of your insurance/risks portfolio.
									Also we explain all insurance terms and conditions to your
									understanding.
								</p>
								<span class="in-service-transparenticon">
									<i class="flaticon-bars"></i>
								</span>
							</div>
						</div> */}
						{/* <!--// Single Service -->

						<!-- Single Service --> */}
						{/* <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
							<div class="in-service mt-30">
								<span class="in-service-icon">
									<i class="flaticon-health-insurance"></i>
								</span>
								<h5>Training</h5>
								<p>We offer free training to your employees in order to appreciate and
									understand insurance workings and operations.</p>
								<span class="in-service-transparenticon">
									<i class="flaticon-health-insurance"></i>
								</span>
							</div>
						</div> */}
						{/* <!--// Single Service -->

						<!-- Single Service --> */}
						{/* <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
							<div class="in-service mt-30">
								<span class="in-service-icon">
									<i class="flaticon-car-1"></i>
								</span>
								<h5>Group Life Scheme</h5>
								<p>We design suitable schemes to meet the pension
									law requirements and better welfare of your employees.
								</p>
								<span class="in-service-transparenticon">
									<i class="flaticon-car-1"></i>
								</span>
							</div>
						</div> */}
						{/* <!--// Single Service --> */}

					</div>
				</div>
			</div>
			{/* <!--// Services Area --> */}
			
			{/* <!-- Call To Action Area --> */}
			{/* <div class="calltoaction-area in-section section-padding-lg need-insure" data-secondary-overlay="9">
				<div class="container">
					<div class="row justify-content-center">
						<div class="col-lg-8">
							<div class="in-cta text-center">
								<h4>NEED AN INSURANCE</h4>
								<h2>FOR FUTURE PLAN</h2>
								
								<Link to="/contact" class="in-button in-button-white">Contact Us</Link>
							</div>
						</div>
					</div>
				</div>
			</div> */}
			{/* <!--// Blog Area --> */}
			<BlogArea />
            </main>

        </div>
    )
}

export default Home;