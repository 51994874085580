import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/images/smoothway_logo.jpg';

// icons
import { FaSearch } from 'react-icons/fa';
import { MdDehaze, MdAdd, MdClear } from 'react-icons/md';

class MobileMenu extends React.Component {
	constructor() {
		super();
		this.state = { isHidden: true, isMenuOpen: true };
		this.handleClick = this.handleClick.bind(this);
		this.MobileMenu = this.MobileMenu.bind(this);
		this.closeMenu = this.closeMenu.bind(this);
	}

	MobileMenu() {
		this.setState({ isMenuOpen: !this.state.isMenuOpen });
	}

	handleClick() {
		this.setState({ isHidden: !this.state.isHidden });
	}

	closeMenu() {
		this.setState({ isMenuOpen: true });
	}

	render() {
		const isHidden = this.state.isHidden;
		const isMenuOpen = this.state.isMenuOpen;
		return (
			<div className="mobile-menu">
				<Link to="/" className="header-logo">
					<img src={Logo} alt="header_image" />
				</Link>
				<div className={isMenuOpen ? "is-nav-invisble" : "is-nav-visible"}>
					<nav>
						<div className="nav-mobile" style={{height:'100vh'}}>
							<ul>
								<li><Link to="/" onClick={this.closeMenu}>HOME</Link></li>
								<li className="in-dropdown">
									<Link to="/about-us" onClick={this.closeMenu}>About Us</Link>
									<span className="btn-drop" onClick={this.handleClick}>{isHidden ? <MdAdd /> : <MdClear />}</span>
									<ul className={isHidden ? "is-nav-invisble" : "service-cat-list"}>
										<li><Link to="/ourdirectors" onClick={this.closeMenu}>Our Directors</Link></li>
										<li><Link to="/our-service" onClick={this.closeMenu}>Our Services</Link></li>
									</ul>
								</li>
								<li className="in-dropdown">
									<Link  style={{ color: "#ffffff" }} onClick={this.handleClick}>Our Products</Link>
									<span className="btn-drop" onClick={this.handleClick}>{isHidden ? <MdAdd /> : <MdClear />}</span>
									<ul className={isHidden ? "is-nav-invisble" : "service-cat-list"}>
										<li><Link to="/clap" onClick={this.closeMenu}>COMPREHENSIVE LIFE ASSURANCE PLAN(CLAP)</Link></li>
										<li><Link to="/home-business-risk" onClick={this.closeMenu}>Home/Business Risk</Link></li>
										<li><Link to="/accident-life-covers" onClick={this.closeMenu}>Accident/Life Covers</Link></li>
										<li><Link to="/motor-vehicle-covers" onClick={this.closeMenu}>Motor Vehicle Covers</Link></li>
										<li><Link to="/legal-liability-covers" onClick={this.closeMenu}>Legal Liability Covers</Link></li>
										<li><Link to="/obligatory-contractual-risk" onClick={this.closeMenu}>Obligatory/Contractual Risks</Link></li>
										<li><Link to="/transit-risk" onClick={this.closeMenu}>Transit Risk</Link></li>
									</ul>
								</li>
								<li><Link to="/premium-finance" onClick={this.closeMenu}>Premium Financing</Link></li>
								<li><Link to="#" onClick={this.closeMenu}>News Update</Link></li>
								<li><Link to="/contact" onClick={this.closeMenu}>Contact</Link></li>
								<li><Link to="/lbsl-rrs" onClick={this.closeMenu}>LBSL-RRS</Link></li>
								<div className="menu-search-bar">
									<form className="inner-search-bar">
										<input type="text" className=" btn-rounded " />
										<button className=" btn-pos btn btn-floating"><FaSearch /></button>
									</form>
								</div>
							</ul>
						</div>
					</nav>
				</div>

				<div className="search-container col-sm-4">
					<form className="search-wrapper">
						<input type="text" className=" btn-rounded btn-outline" />
						<button className=" btn-pos btn btn-floating"><FaSearch /></button>
					</form>
					<span className="open-close" onClick={this.MobileMenu}>
						{isMenuOpen ? <MdDehaze size={30} /> : <MdClear size={30} />}
					</span>
				</div>
			</div>
		);
	}
}

export default MobileMenu;
