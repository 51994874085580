import { Routes, Route } from "react-router-dom";

import HeaderTop from "./components/HeadertTop";
import HeaderBottom from "./components/HeaderBottom";
import Footer from "./components/Footer";

//  pages
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import OurServices from "./pages/OurServices";
import PremiumFinance from "./pages/PremiumFinance";
import Contact from "./pages/Contact";
import LBSLRRS from "./pages/LBSLRRS";
import Clap from "./pages/products_componentsdetails/Clap";
import HomeBusinessRisk from "./pages/products_componentsdetails/HomeBusinessRisk";
import AccidentLifeCovers from "./pages/products_componentsdetails/AccidentLifeCovers";
import MotorVehicleCover from "./pages/products_componentsdetails/MotorVehicleCovers";
import LegalLiabiltyCover from "./pages/products_componentsdetails/LegalLiabiltyCover";
import Obligatory from "./pages/products_componentsdetails/OblLiabilityCvr";
import TransitRisk from "./pages/products_componentsdetails/TransitRisk";
import MainForm from "./clapform/Mainform";

// css rules
import "./style.css";
import "./App.css";
import "./bootstrap.min.css";
import OurTeam from "./pages/OurTeam";

/* try {
  window.$ = window.jQuery = require('jquery');
  window.popper = require('popper.js');
  require('./assets/js/bootstrap.min.js');
  require('./assets/js/popper.min.js');
  require('./assets/js/main.js');
  require('./assets/js/plugins.js');
  require('./assets/js/vendor/jquery-3.3.3.min.js');
  require('./assets/js/vendor/modernizr-3.6.0.min.js');
} catch (e) {
  console.log(e);
} */

function App() {
  return (
    <div className="App">
      <HeaderTop />
        <HeaderBottom />
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/ourdirectors" element={<OurTeam />} />
            <Route path="/our-service" element={<OurServices />} />
            <Route path="/premium-finance" element={<PremiumFinance />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/lbsl-rrs" element={<LBSLRRS />} />
            <Route path="/clap" element={<Clap/>} />
            <Route
              path="/home-business-risk"
            
              element={<HomeBusinessRisk/>}
            />
            <Route
              path="/accident-life-covers"
            
              element={<AccidentLifeCovers/>}
            />
            <Route
              path="/motor-vehicle-covers"
            
              element={<MotorVehicleCover/>}
            />
            <Route
              path="/legal-liability-covers"
            
              element={<LegalLiabiltyCover/>}
            />
            <Route
              path="/obligatory-contractual-risk"
            
              element={<Obligatory/>}
            />
            <Route path="/transit-risk" element={<TransitRisk/>} />
            <Route path="/clap-form" element={<MainForm/>} />
          </Routes>
        </div>
        <Footer />
    </div>
  );
}

export default App;
