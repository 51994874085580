import React from 'react';
// import {Link} from 'react-router-dom';
// import OurServiceCartegory from '../components/OurServiceCartegory';

const OurServices = () => {
    return(
        <div>
			
                {/* <!-- Breadcrumb --> */}
                <div className=" d-flex align-items-center prem-bg"
			 data-secondary-overlay="8">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-10">
							<div className=" text-center heroslider-content">
								<h1>Our Services </h1>
							</div>
						</div>
					</div>
				</div>
			</div>
            {/* <!--// Breadcrumb -->
            <!-- Page Conttent --> */}
            <main class="page-content">
            <div class="services-area in-section section-padding-lg bg-shape">
				<div class="container">
					<div class="row justify-content-center">
						<div class="col-lg-6">
							<div class="section-title text-center">
								<h6>We do things a bit differently, and that's the way we like it!</h6>
								
							</div>
						</div>
					</div>
					<div class="row">

						{/* <!-- Single Service --> */}
						<div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
							<div class="in-service mt-30">
								<span class="in-service-icon">
									<i class="flaticon-life-insurence"></i>
								</span>
								<h5>Risk Management</h5>
								<p>We offer professional risk survey of your operations in
									order to identify and evaluate all the risk exposure of your business and
									advise the appropriate mechanism  for prevention, elimination or transfer.</p>
								<span class="in-service-transparenticon">
									<i class="flaticon-life-insurence"></i>
								</span>
							</div>
						</div>
						{/* <!--// Single Service -->

						<!-- Single Service --> */}
						<div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
							<div class="in-service mt-30">
								<span class="in-service-icon">
									<i class="flaticon-mortgage"></i>
								</span>
								<h5>Risk Placement</h5>
								<p>We recommend and place your insurance covers cost effectively.</p>
								<span class="in-service-transparenticon">
									<i class="flaticon-mortgage"></i>
								</span>
							</div>
						</div>
						{/* <!--// Single Service -->

						<!-- Single Service --> */}
						<div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
							<div class="in-service mt-30">
								<span class="in-service-icon">
									<i class="flaticon-van"></i>
								</span>
								<h5>Risk Review</h5>
								<p>We regularly review your risks in line with the state of the economy.</p>
								<span class="in-service-transparenticon">
									<i class="flaticon-van"></i>
								</span>
							</div>
						</div>
						{/* <!--// Single Service -->

						<!-- Single Service --> */}
						<div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
							<div class="in-service mt-30">
								<span class="in-service-icon">
									<i class="flaticon-bars"></i>
								</span>
								<h5>Record Keeping / Interpretation</h5>
								<p>We keep adequate records of your insurance/risks portfolio.
									Also we explain all insurance terms and conditions to your
									understanding.
								</p>
								<span class="in-service-transparenticon">
									<i class="flaticon-bars"></i>
								</span>
							</div>
						</div>
						{/* <!--// Single Service -->

						<!-- Single Service --> */}
						<div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
							<div class="in-service mt-30">
								<span class="in-service-icon">
									<i class="flaticon-health-insurance"></i>
								</span>
								<h5>Training</h5>
								<p>We offer free training to your employees in order to appreciate and
									understand insurance workings and operations.</p>
								<span class="in-service-transparenticon">
									<i class="flaticon-health-insurance"></i>
								</span>
							</div>
						</div>
						{/* <!--// Single Service -->

						<!-- Single Service --> */}
						<div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
							<div class="in-service mt-30">
								<span class="in-service-icon">
									<i class="flaticon-car-1"></i>
								</span>
								<h5>Group Life Scheme</h5>
								<p>We design suitable schemes to meet the pension
									law requirements and better welfare of your employees.
								</p>
								<span class="in-service-transparenticon">
									<i class="flaticon-car-1"></i>
								</span>
							</div>
						</div>
						{/* <!--// Single Service --> */}

					</div>
				</div>
			</div>
			{/* <!--// Services Area --> */}
            </main>
            {/* <!--// Page Conttent --> */}
        </div>
    )
}

export default OurServices;