import React, { Component } from "react";
import { Link } from "react-router-dom";

// icons
import { FaSearch, FaWindowClose } from "react-icons/fa";

import Logo from "../assets/images/smoothway_logo.jpg";
import MobileMenu from "./MobileMenu";

class HeaderBottom extends Component {
  constructor() {
    super();
    this.state = { isHidden: true };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState({ isHidden: !this.state.isHidden });
  }

  render() {
    const isHidden = this.state.isHidden;
    return (
      <div>
        {/* Header Bottom Area */}
        <div className="header-bottomarea">
          <div className="container">
            <div className="header-bottom">
              {/* Header Logo */}
              <Link to="/" className="header-logo">
                <img src={Logo} alt="header_image" />
              </Link>
              {/* Header Logo */}

              {/* Main Navigation */}
              <nav className="in-navigation">
                <ul>
                  <li>
                    <Link to="/">HOME</Link>
                  </li>
                  <li className="in-dropdown">
                    <Link to="/about-us">About Us</Link>
                    <ul class="service-cat-list">
                      <li>
                        <Link to="/ourdirectors">
                          Our Directors
                        </Link>
                      </li>
                      <li>
                    <li>
                      <Link to="/our-service">Our Services</Link>
                    </li>
                  </li>
                    </ul>
                  </li>
                  
                  <li className="in-dropdown">
                    <Link  style={{ color: "#2b2b2b" }}>
                      Our Products
                    </Link>
                    <ul class="service-cat-list">
                      <li>
                        <Link to="/clap">
                          COMPREHENSIVE LIFE ASSURANCE PLAN(CLAP)
                        </Link>
                      </li>
                      <li>
                        <Link to="/home-business-risk">
                          Home/Bussiness Risk
                        </Link>
                      </li>
                      <li>
                        <Link to="/accident-life-covers">
                          Accident/Life Covers
                        </Link>
                      </li>
                      <li>
                        <Link to="/motor-vehicle-covers">
                          Motor Vehicle Covers
                        </Link>
                      </li>
                      <li>
                        <Link to="/legal-liability-covers">
                          Legal Liability Covers
                        </Link>
                      </li>
                      <li>
                        <Link to="/obligatory-contractual-risk">
                          Obligatory/Contractual Risks
                        </Link>
                      </li>
                      <li>
                        <Link to="/transit-risk">Transit Risk</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/premium-finance">Premium Financing</Link>
                  </li>
                  <li>
                    <Link to="#">News Update</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                  <li>
                    <Link to="/lbsl-rrs">LBSL-RRS</Link>
                  </li>
                </ul>
              </nav>
              {/* Main Navigation */}

              <div className="header-right-wrap">
                {/* Header Search */}
                <div className="header-search">
                  <button
                    className="header-searchtrigger"
                    onClick={this.handleClick}
                  >
                    <i className="zmdi zmdi-search">
                      {isHidden ? (
                        <FaSearch className="zmdi" size={15} />
                      ) : (
                        <FaWindowClose size={15} />
                      )}
                    </i>
                  </button>
                  <form
                    className={
                      isHidden
                        ? "header-searchbox"
                        : "header-searchbox-is-visible"
                    }
                    action="#"
                  >
                    <input type="text" placeholder="Search..." />
                    <button type="submit" className="search-box">
                      <i className="zmdi zmdi-search">
                        <FaSearch size={20} />
                      </i>
                    </button>
                  </form>
                </div>
                {/* Header Search */}
              </div>
            </div>
          </div>
        </div>
        {/* Header Bottom Area */}

        {/* Mobile Menu */}
        <div className="mobile-menu-wrapper clearfix">
          <div className="container">
            <MobileMenu />
          </div>
        </div>
        {/* Mobile Menu */}
      </div>
    );
  }
}

export default HeaderBottom;
