import React from 'react';
import Aside from '../../components/Aside';
import Blockquote from '../../components/Blockquote';
import BusinessRiskForm from '../../components/form-component/HomeBusinessRisksForm';

const TransitRisk = () => {
    return(
        <div>
            <div classNameName=" d-flex align-items-center "
                data-secondary-overlay="8">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className=" text-center heroslider-content">
                                    <h2>TRANSIT RISKS</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        <div className="container">
        <div className=" row ">
        <div className="col-lg-8 pr-0">
            <div class="service-details-content">
                <ol type="A" class="car-insurance mt-50">
                    <li>
                        <h5>MARINE CARGO RISKS</h5>
                        <ul>
                            <li>
                                <h6>What is covered:</h6>
                                Compensation for loss or damage to the imported/exported cargos due 
                                to the perils of the sea or air voyage from the point of loading to 
                                the designated point of discharge. Cover could be on all risks basis 
                                or total loss or general loss basis. It could be on single transit 
                                or open cover basis.
                            </li>
                            <li>
                                <h6>Who can be insured:</h6>
                                Importer or Exporter of the Cargo and/or other interested party 
                                on the consignment like banks and other financiers.
                            </li>
                            <li>
                                <h6>Detail Required</h6>
                                <ol type="i">
                                    <li>Copy of the proforma invoice relating to the 
                                        cargo due for shipment. This will detail the 
                                        escription of the goods to be shipped.
                                    </li>
                                    <li>The destination.</li>
                                    <li>Port of dispatch.</li>
                                    <li>The value.</li>
                                    <li>The consignee.</li>
                                    <li>The consignor.</li>
                                    <li>
                                        Type of cover required:
                                        <ul>
                                            <li>All Risks.</li>
                                            <li>Total Loss.</li>
                                            <li>General Average.</li>
                                        </ul>
                                    </li>

                                </ol>
                            </li>
                        </ul>
                    </li>
                </ol>
            </div>
        </div>
        <div className="col-lg-3 pl-0 offset-lg-1">
            <Aside className="section-padding-bottom-xs"/>
        </div>
        </div>
            <Blockquote />
            
            <BusinessRiskForm />
        </div>
        </div>
    )
}

export default TransitRisk;