import React from 'react';

class DeathBenefits extends React.Component{

    render(){
        return(
            <div >
            <div className="row col-md-12 space-top-bottom">
                <div className="col-sm-4" >
                    <h6><strong>Death</strong></h6>
                    <label>Amount: #250,000</label>
                    <input type="text" placeholder="Enter numbers only(e.g 1 - 100 or above)"/>
                </div>
                <div className="col-sm-4">
                    <h6>Desired Benefits (Death)Total</h6>
                    <label>Amount: input amount shows here</label>
                </div>
                <div className="col-sm-4">
                    <h6>Premium (Death)</h6>
                    <label>Amount: 1% of input amount shows here</label><br/>
                    <span>@1%</span>
                </div>
            </div>
            <div className="">
                <h6>Total Premium</h6>
                <label>Amount: 1% of input amount shows here</label>
                
            </div>
            </div>
        )
    }
}

export default DeathBenefits;