import React from 'react';
import Aside from '../../components/Aside';
import Blockquote from '../../components/Blockquote';
import DetailRequired from '../../components/DetailRequired';
import BusinessRiskForm from '../../components/form-component/HomeBusinessRisksForm';

const HomeBusinessRisk = () => {
    return(
        <div>
            <div classNameName=" d-flex align-items-center "
                data-secondary-overlay="8">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className=" text-center heroslider-content">
                                    <h2>HOME/BUSINESS RISKS</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        <div className="container">
        <div className=" row ">
        <div className="col-lg-8 pr-0">
            <div class="service-details-content">
                <ol type="A" class="car-insurance mt-50">
                    <li>
                        <h5>FIRE & SPECIAL PERILS</h5>
                        <ul>
                            <li>
                                <h6>Risk Covered:</h6>
                                Payment of compensation for loss or damage to the insured
                                items caused by Fire, lightning, explosion, flood damages, storms, earthquake,
                                riot strike & civil commotion and other related natural consequences.
                            </li>
                            <li>
                                <h6>What can be insured:</h6>
                                Building, all movable and immovable contents therein, including but not limited
                                to furniture, household fittings and fixtures, electronic equipment and home/kitchen
                                appliances of every description etc
                            </li>
                            <DetailRequired />
                        </ul>
                    </li>

                    <li>
                        <h5>BURGLARY & HOUSEBREAKING OR THEFT</h5>
                        <ul>
                            <li>
                                <h6>Risk Covered:</h6>
                                Payment of compensation for loss or damage to the insured items 
                                following housebreaking and/or by forcible entry or exit from 
                                the insured premises.
                            </li>
                            <li>
                                <h6>What can be insured:</h6>
                                All movable items including but not limited to home appliances of every description, furniture, fixtures and fittings, electronic equipment and kitchen appliances
                            </li>
                            <DetailRequired />
                        </ul>
                    </li>
                    <li>
                        <h5>MONEY OR CASH</h5>
                        <ul>
                            <li>
                                <h6>Risk Covered:</h6>
                                Compensation for loss or damage to cash whilst in transit to and from office/home/bank/customers/sites; in and out of safe; and in personal custody of specified individuals.
                            </li>
                            <li>
                                <h6>What can be insured:</h6>
                                Currencies, and other similar fidicuary instruments legal tenders
                            </li>
                            <li>
                                <h6>Details Required: </h6>
                                <ol type="i">
                                    <li>Probable Total Annual Cash/Currency Movements</li>
                                    <li>Probable Maximum Cash/Currency to be carried at a time.</li>
                                    <li>Probable Maximum Cash to be kept in/out of safe at a time</li>
                                    <li>Probable Maximum Cash that can be in personal possession
                                        of named person(s) or number of individuals to be covered.
                                    </li>
                                </ol>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h5>FIDELITY GUARANTEE</h5>
                        <ul>
                            <li>
                                <h6>Risk Covered:</h6>
                                Payment of compensation for loss or damage to an employer (or for which an employer
                                may be responsible) due to the infidelity act of the employee(s) directly or
                                indirectly arising from fraud,
                                embezzlement act of the employee, or while the employee(s) acts in connivance.
                            </li>
                            <li>
                                <h6>What can be insured:</h6>
                                Individual Employee(s); Group of Employee(s); All Employees
                            </li>
                            <li>
                                <h6>Details Required: </h6>
                                <ol type="i">
                                    <li>Probable Annual Total loss due to insured Employees Infidelity.</li>
                                    <li>Probable loss due to the employee infidelity in one event.</li>
                                    <li>Name(s)/Position(s)/No of employees to be covered.</li>
                                </ol>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h5>GOODS-IN-TRANSIT/MARINE INLAND RISKS</h5>
                        <ul>
                            <li>
                                <h6>Risk Covered:</h6>
                                Payment of compensation for loss or damage to insured
                                goods whilst in transit from point of loading to final
                                destination within the country.
                            </li>
                            <li>
                                <h6>What can be insured:</h6>
                                Any consignment of value being carried by road, rail, air, and waterways within the area of jurisdiction covered.
                            </li>
                            <li>
                                <h6>Details Required: </h6>
                                <ol type="i">
                                    <li>Probable Total Annual Value of Goods to be moved</li>
                                    <li>Probable Maximum Value of Goods to be carried at a time.</li>
                                    <li>Descriptions of possible items to be moved.</li>
                                    <li>Mode of transportation: (Road, Air, Rail, Water), and
                                        Ownership of means of Transportation: (Owned or Hired)
                                    </li>
                                    <li>Frequency of transit: single transit? or more frequent  transits (please indicate probable time per: week/month/year)</li>
                                </ol>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h5>PLANTS ALL RISKS</h5>
                        <ul>
                            <li>
                                <h6>Risk Covered:</h6>
                                Payments of compensation for all losses or damages to plants
                                and machineries of various descriptions.
                            </li>
                            <li>
                                <h6>What can be insured:</h6>
                                Electric generators, Graders, Earth moving machines, Water Treatment Plants and other specialized equipment.
                            </li>
                            <DetailRequired />
                        </ul>
                    </li>
                    <li>
                        <h5>HOUSEHOLDER/HOUSEOWNER COMPREHENSIVE</h5>
                        <ul>
                            <li>
                                <h6>Risk Covered:</h6>
                                <ol type="i">
                                    <p>Payment of compensation for</p>
                                    <li> loss or damage to insured items caused by  fire, lightning, explosion, flood damages, storms, earthquake, riot strike & civil commotion and other related natural consequences or</li>
                                    <li>loss or damage following housebreaking and/or by forcible entry or exit from the insured premises.</li>
                                    <li>personal legal liabilities of the owner/occupier of the insured building to their neighbour, visitors and guest and</li>
                                    <li>Personal Accident to the owner/occupier of an insured building leading to death/incapacitation or specified expenses.</li>
                                </ol>
                            </li>
                            <li>
                                <h6>What can be insured:</h6>
                                <ol type="i">
                                    <li>Building, and/or all movable and immovable contents of the building such as furniture,
                                        household fittings and fixtures and home appliances etc
                                    </li>
                                    <li>general third party legal liability arising from ownership/occupation of an insured building</li>
                                    <li>death permanent disability, temporary disability, medical expenses and burial expenses in respect
                                         of the insured person and the family arising from accidental means such as armed robbery attack, etc.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <h6>Details Required: </h6>
                                <ol type="i">
                                    <li>Location address of items to be insured.</li>
                                    <li>Description (Make & Model) of items to be insured.</li>
                                    <li>Current Market Value of items to be insured.</li>
                                    <li>Date items insured is purchased.</li>
                                    <li>Limit of Liability to be paid in the event of legal action.</li>
                                    <li>Benefit desired for death and permanent disability, temporary.</li>
                                    <li>disability, medical expenses, and burial expenses following an accident to the insured person.</li>
                                </ol>
                            </li>
                        </ul>
                    </li>
                </ol>
            </div>
        </div>
        <div className="col-lg-3 pl-0 offset-lg-1">
            <Aside className="section-padding-bottom-xs"/>
        </div>
        </div>
            <Blockquote />
            
            <BusinessRiskForm />

        </div>
        </div>
    )
}

export default HomeBusinessRisk;