import React from 'react';
import './FormSubmissionSummary.css';
import Smoothway from '../../assets/images/smoothway_logo.jpg'

const FormSubmissionSummary = ({ personalData, desiredBenefits, medicalHistory, otherAssurance }) => {
  return (
    <div className="formcontainer" id="form-summary">
      <div className="logo">
        <img src={Smoothway} alt="Smoothway Logo" style={{ maxWidth: '150px' }} />
      </div>

      <div className="section">
        <h2>CLAP Form Submission Summary</h2>

        <div className="section">
          <h3>Step 1: Personal Data</h3>
          <div style={{display:'flex', flexWrap:'wrap', gap:'10px'}}>
            <p><strong>Name:</strong>{personalData.title}  {" "} {personalData.lastName + personalData.firstName + personalData.middleName}</p>
            <p><strong>Marital Status:</strong> {personalData.maritalStatus}</p>
            <p><strong>Address:</strong> {personalData.address}</p>
            <p><strong>Work Phone:</strong> {personalData.workPhone}</p>
            <p><strong>Email:</strong> {personalData.email}</p>
            <p><strong>Mobile Phone:</strong> {personalData.mobilePhone}</p>
            <p><strong>Occupation:</strong> {personalData.occupation}</p>
            <p><strong>Nationality:</strong> {personalData.nationality}</p>
            <p><strong>Date of Birth:</strong> {personalData.dateOfBirth}</p>
            <p><strong>State of Origin:</strong> {personalData.stateOfOrigin}</p>
            <p><strong>Place of Birth:</strong> {personalData.placeOfBirth}</p>
            <p><strong>BVN Number:</strong> {personalData.bvnNumber}</p>
          </div>
        </div>

        <div className="section">
          <h3>Step 2: Desired Benefits</h3>
          <p><strong>Selected Benefit:</strong> {desiredBenefits.selectedBenefit}</p>
          <p><strong>Commencement Date:</strong> {desiredBenefits.commencementDate}</p>
          <h4>Beneficiaries:</h4>
          <ul>
            {desiredBenefits.beneficiaries.map((beneficiary, index) => (
              <li key={index}>
                <p><strong>Name:</strong> {beneficiary.name}</p>
                <p><strong>Relationship:</strong> {beneficiary.relationship}</p>
              </li>
            ))}
          </ul>
        </div>

        <div className="section">
          <h3>Step 3: Medical History</h3>
          <table>
            <thead>
              <tr>
                <th>Question</th>
                <th>Answer</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(medicalHistory.questions).map((question) => (
                <tr key={question}>
                  <td>{question}</td>
                  <td>{medicalHistory.questions[question]}</td>
                  <td>{medicalHistory.details[question]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="section">
          <h3>Step 4: Other Assurance</h3>
          <p><strong>Chest Disease:</strong> {otherAssurance.chestDisease}</p>
          <p><strong>Declaration Checked:</strong> {otherAssurance.declarationChecked ? "Yes" : "No"}</p>
          <p><strong>Preferred Insurance Company:</strong> {otherAssurance.preferredInsuranceCompany || "N/A"}</p>
        </div>

        <p className="thank-you">Thank you for submitting the form.</p>
      </div>
    </div>
  );
};

export default FormSubmissionSummary;
