import React, { Component } from "react";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";

class OtherAssurance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chestDisease: "", 
      declarationChecked: false, 
      preferredInsuranceCompany: "", 
    };
  }

  handleChange = (e) => {
    if (e.target.type === "radio") {
      this.setState({ chestDisease: e.target.value });
    } else if (e.target.type === "checkbox") {
      this.setState({ declarationChecked: e.target.checked });
    } else if (e.target.type === "select-one") {
      this.setState({ preferredInsuranceCompany: e.target.value });
    }
  };

  nextForm = (e) => {
    e.preventDefault();
    const { chestDisease, declarationChecked, preferredInsuranceCompany} = this.state
    const  data= { chestDisease, declarationChecked, preferredInsuranceCompany} 
    // Pass the current state to the parent component or perform any other logic here
    this.props.onSubmit(data);
    this.props.nextStep();
  };

  prevForm = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { chestDisease, declarationChecked, preferredInsuranceCompany } =
      this.state;

    return (
      <div>
        <h4 className="form-heading">Other Assurance on Your Life</h4>
        <div className="row space-top-bottom">
          <div className="marital-container col-sm-6">
            <div className="marital-col">
              <label>
                Tuberculosis, Asthma, Pneumonia or any other chest disease?
              </label>
              <ul className="mar marital-status col-sm-2">
                <input
                  type="radio"
                  value="Yes"
                  checked={chestDisease === "Yes"}
                  onChange={this.handleChange}
                />
                Yes
              </ul>
              <ul className="mar marital-status col-sm-2">
                <input
                  type="radio"
                  value="No"
                  checked={chestDisease === "No"}
                  onChange={this.handleChange}
                />
                No
              </ul>
            </div>
          </div>
        </div>
        <div className="clap-form-container">
          <label>Preferred Insurance Company</label>
          <select
            value={preferredInsuranceCompany}
            onChange={this.handleChange}
          >
            <option value="Allianz Nigeria Insurance Plc." selected="selected">
              Allianz Nigeria Insurance Plc.
            </option>
            <option value="Anchor Insurance Co. Ltd">
              Anchor Insurance Co. Ltd
            </option>
            <option value="ARM Life Assurance Plc.">
              ARM Life Assurance Plc.
            </option>
            <option value="AXA Mansard Insurance Plc.">
              AXA Mansard Insurance Plc.
            </option>
            <option value="Capital Express Assurance Co. Ltd">
              Capital Express Assurance Co. Ltd
            </option>
            <option value="Consolidated Hallmark Insurance Plc">
              Consolidated Hallmark Insurance Plc
            </option>
            <option value="Cornerstone Insurance Plc">
              Cornerstone Insurance Plc
            </option>
            <option value="Custodian &amp; Allied Insurance Plc">
              Custodian &amp; Allied Insurance Plc
            </option>
            <option value="Custodian Life Assurance Limited">
              Custodian Life Assurance Limited
            </option>
            <option value="FBN General Insurance Co. Ltd.">
              FBN General Insurance Co. Ltd.
            </option>
            <option value="FBN Life Assurance Ltd.">
              FBN Life Assurance Ltd.
            </option>
            <option value="Fin Insurance Plc.">Fin Insurance Plc.</option>
            <option value="Goldlink Insurance Plc">
              Goldlink Insurance Plc
            </option>
            <option value="Great Nigeria Insurance Plc">
              Great Nigeria Insurance Plc
            </option>
            <option value="Guinea Insurance Plc">Guinea Insurance Plc</option>
            <option value="Industrial &amp; General Insurance Plc.">
              Industrial &amp; General Insurance Plc.
            </option>
            <option value="International Energy Insurance Plc.">
              International Energy Insurance Plc.
            </option>
            <option value="Jaiz Takaful Insurance Plc.">
              Jaiz Takaful Insurance Plc.
            </option>
            <option value="KBL Insurance Ltd.">KBL Insurance Ltd.</option>
            <option value="LASACO Assurance Plc">LASACO Assurance Plc</option>
            <option value="Law Union &amp; Rock Insurance Plc">
              Law Union &amp; Rock Insurance Plc
            </option>
            <option value="Leadway Assurance Co. Ltd">
              Leadway Assurance Co. Ltd
            </option>
            <option value="Linkage Assurance Plc">Linkage Assurance Plc</option>
            <option value="Metropolitan Life Insurance Nig. Ltd">
              Metropolitan Life Insurance Nig. Ltd
            </option>
            <option value="Mutual Benefits Assurance Plc">
              Mutual Benefits Assurance Plc
            </option>
            <option value="Mutual Benefits Life Assurance Plc.">
              Mutual Benefits Life Assurance Plc.
            </option>
            <option value="NEM Insurance Plc">NEM Insurance Plc</option>
            <option value="NICON Insurance Limited">
              NICON Insurance Limited
            </option>
            <option value="Niger Insurance Plc.">Niger Insurance Plc.</option>
            <option value="Noor Takaful PLC">Noor Takaful PLC</option>
            <option value="NSIA Insurance Company Ltd">
              NSIA Insurance Company Ltd
            </option>
            <option value="Old Mutual Nig. General Ins. Co. Limited">
              Old Mutual Nig. General Ins. Co. Limited
            </option>
            <option value="Old Mutual Nigeria Life Assurance Company">
              Old Mutual Nigeria Life Assurance Company
            </option>
            <option value="Prestige Assurance Plc.">
              Prestige Assurance Plc.
            </option>
            <option value="Prudential Zenith Life Insurance Plc.">
              Prudential Zenith Life Insurance Plc.
            </option>
            <option value="Regency Alliance Insurance Plc.">
              Regency Alliance Insurance Plc.
            </option>
            <option value="Royal Exchange General Insurance Co. Ltd">
              Royal Exchange General Insurance Co. Ltd
            </option>
            <option value="Royal Exchange Prudential Life Plc.">
              Royal Exchange Prudential Life Plc.
            </option>
            <option value="Saham Unitrust Insurance Nigeria Ltd.">
              Saham Unitrust Insurance Nigeria Ltd.
            </option>
            <option value="Sovereign Trust Insurance Plc.">
              Sovereign Trust Insurance Plc.
            </option>
            <option value="STACO Assurance Plc">STACO Assurance Plc</option>
            <option value="Standard Alliance Insurance Plc.">
              Standard Alliance Insurance Plc.
            </option>
            <option value="Sterling Assurance Co. Ltd.">
              Sterling Assurance Co. Ltd.
            </option>
            <option value="Sunu Assurance Nigeria Plc.">
              Sunu Assurance Nigeria Plc.
            </option>
            <option value="Universal Insurance Plc">
              Universal Insurance Plc
            </option>
            <option value="Veritas Kapital Assurance Plc">
              Veritas Kapital Assurance Plc
            </option>
            <option value="Wapic Insurance Plc.">Wapic Insurance Plc.</option>
            <option value="Wapic Life Assurance Ltd">
              Wapic Life Assurance Ltd
            </option>
            <option value="Zenith General Insurance Co. Ltd">
              Zenith General Insurance Co. Ltd
            </option>
          </select>
        </div>
        <div className="row">
          <div className="marital-container col-sm-6">
            <div className="marital-col">
              <label>Declaration</label>
              <ul className="mar marital-status col-sm-2">
                <input
                  type="checkbox"
                  checked={declarationChecked}
                  onChange={this.handleChange}
                />
                No
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-5">
            <button className="clap-next" onClick={this.nextForm}>
              Submit <MdNavigateNext />
            </button>
          </div>
          <div className="col-sm-5">
            <button className="clap-next" onClick={this.prevForm}>
              <MdNavigateBefore /> Previous
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default OtherAssurance;
