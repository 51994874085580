import React from 'react';

class BurialExpenses extends React.Component{

    render(){
        return(
            <div >
            <div className="row col-md-12 space-top-bottom">
                <div className="col-sm-4" >
                    <h6><strong>Burial Expenses</strong></h6>
                    <label>Amount: #250,000</label>
                    <input type="text" placeholder="Enter numbers only(e.g 1 - 100 or above)"/>
                </div>
                <div className="col-sm-4">
                    <h6>Desired Benefits (Burial Bill)Total</h6>
                    <label>Amount: input amount shows here</label>
                </div>
                <div className="col-sm-4">
                    <h6>Premium (Burial Bill)</h6>
                    <label>Amount: 0.75% of input amount shows here</label><br/>
                    <span>@0.75%</span>
                </div>
            </div>
            <div className="">
                <h6>Total Premium</h6>
                <label>Amount: 0.75% of input amount shows here</label>
            </div>
            </div>
        )
    }
}

export default BurialExpenses;