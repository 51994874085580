import React from 'react';
import Prem from '../assets/images/other/prem-fin1.jpg'


const formPadding ={paddingLeft: '10px', paddingRight: '10px'};

const PremiumFinance = () => {
    return(
        <div>
            <div className=" d-flex align-items-center prem-bg"
			 data-secondary-overlay="8">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-10">
							<div className=" text-center heroslider-content">
								<h1><span>Conditions For</span>Premium Finance</h1>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="about-area in-section section-padding-top-lg bg-white" style={{paddingTop: '100px'}}>
				<div className="container custom-container">
					<div className="row no-gutters">
						<div className="col-xl-6 col-lg-12">
							<div className="premium-content heightmatch rd-padding">
								
								<h4>We finance your premium on the following terms and conditions.</h4>
								<ol>
									<li><strong>You</strong> pick and/or decide your desired insurance cover by completing the data form below and</li>
									<li><strong>We</strong> advise the annual premium due and</li>
									<li><strong>You</strong> pay 20% of the gross premium (non-refundable) (by cheque or transfer) and</li>
									<li><strong>You</strong> issue, in favour of “SMOOTHWAY INSURANCE BROKERS LTD”, not more than post-dated cheque(s) with not more than 28 days interval as will be advised and</li>
									<li><strong>You</strong> sign (check) the attached (please download) Declaration Form below and</li>
								</ol>
								<p>We deliver your insurance policy to you as soon as possible.</p>
								<div style={{paddingTop: '30px'}}>
									<span className="premium-content" style={{fontStyle: 'italic'}}><strong>Please take note:</strong></span>
									<div style={{marginLeft: '15px'}}>
										<p style={{paddingTop: '20px'}}>PREMIUM TO BE FINANCED</p>
										<p>INDIVIDUAL: N100,000.00 (AND ABOVE) PER ANNUM</p>
										<p>CORPORATE: N250,000.00 (AND ABOVE) PER ANNUM</p>
										<p>IN THE EVENT OF CLAIM, THE TOTAL OUTSTANDING PREMIUM AS AT DATE BECOME PAYABLE IMMEDIATELY.<p/>
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6">
							<div className="heightmatch">
								<div >
									<img src={Prem} alt="man with umbrella" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="features-area in-section bg-white ">
				<div className="about-area in-section ">
					<div className="container custom-container ">
						<div>	
							<div className="section-padding-top-md section-padding-bottom-lg">
								<div className="form-message ">
									<h2 className=" premium-content-div">APPLICATION FOR PREMIUM FINANCING</h2>
									<h5 className=" premium-content-div" >CUSTOMER DATA</h5>
								</div>
								<div className="insurencebox-p heightmatch rd-padding">
									<form action="#" className="insurencebox-form no-gutters">
										<div className= "row section-padding-bottom-sm">
										<div className="col-xl-6 col-lg-12" style={formPadding}>
											<input type="text" placeholder="Firstname " />
											<input type="text" placeholder="Lastname " />	
											<input type="text" placeholder="Email " />
											<input type="text" placeholder="Phone " />
											<input type="text" placeholder="Home Address " />
											<input type="text" placeholder="Office Address " />
										</div>
					
										<div className="col-xl-6 col-lg-12">
											<input type="text" placeholder="Date of Birth" />
											<input type="text" placeholder="Name of Employer or Company if Self Employed" />
											<input type="text" placeholder="Date joined employment or start self-employment" />
											<input type="text" placeholder="Position in the Employment" />
											<input type="text" placeholder="Name of Banker and Branch" />
										</div>
										
										</div>
										<hr />
										<h4 className=" premium-content-div" >Risk Details</h4>
										<div className= "row section-padding-top-sm">
											<div className="col-xl-6 col-lg-12" style={formPadding}>
												<input type="text" placeholder="Class of Insurance " />
												<input type="text" placeholder="Name of Insured " />	
												<input type="text" placeholder="Preferred Insurance Company " />
												<input type="text" placeholder="From " />
												<input type="text" placeholder="To " />
												<label>Maximum is 12 months</label>
												<input type="text" placeholder="Value of Assets to be insured " />
												<input type="text" placeholder="Description and Location Address of Assets to be insured" />
											</div>
										</div>
										<hr />
										<div style={{paddingTop: '20px'}}>
											<span>Registration Number, Make & Model for Motor Vehicles</span>
											<p className="row" style={{fontSize: '12px', width:'80%',paddingTop: '15px'}}>
												<div className="col-xl-1 col-lg-1" >
													<input style={{height: '12px'}} type="radio" />
												</div>
												
												<span className="col-xl-7 col-lg-12" >I do solemnly declared and state that the information hereby supplied
												are complete and correct and hereby agreed that the insurance cover hereby financed by this
												program is subject to the AUTOMATIC CANCELLATION clause which allows SMOOTHWAY INSURANCE BROKERS LTD
												or its agent or representative to cancel without any further notice the insurance policy related
												thereto and obtain full premium refund accrued from the cancelation and only in the event of the
												post-dated cheques being lodged for the financing of the premium is been dis-honoured for payment
												for whatever reasons notwithstanding the facts that the insurance is still active.</span>
												
												<span style={{paddingTop: '15px'}}>By checking the submission box herein, you or your agent or representative have agreed to the terms and conditions of this<br /> scheme whether expressed or implied.</span>
											</p>
											
										</div>
										<button type="submit" className="in-button">Submit</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <!--// About Area --> */}
		</div>
    )
}

export default PremiumFinance;