import React from 'react'

const ReqLiabiltyCover = () => {
    return(
        <li>
            <h6>Details Required:</h6>
            <ol type="i">
                <li>Details of the Individual’s or Corporate Occupation/Operation.</li>
                <li>Location of Insured Person’s Premises/Business/Operation.</li>
                <li>Probable Legal Cost/Expense that may be incurred in one event.</li>
                <li>Probable Aggregate Legal Cost/Expenses incurable in a year. </li>
            </ol>
        </li>
    )
}

export default ReqLiabiltyCover;