import React from 'react'

const DetailReqAcdt = () => {
    return(
        <li>
            <h6>Detail Required</h6>
            <ol type="i">
                <li>Owners Name as shown on the Vehicle Licence(s)</li>
                <li>Make and Model of Vehicle(s)</li>
                <li>Registration of the Vehicle(s)</li>
                <li>Purpose for which the vehicle(s) is/are used (Private or Reward)</li>
                <li>The Current Market Value of the Vehicle(s) </li>
            </ol>
        </li>
    )
}

export default DetailReqAcdt;