import React from 'react';
import Aside from '../../components/Aside';
import Blockquote from '../../components/Blockquote';
import BusinessRiskForm from '../../components/form-component/HomeBusinessRisksForm';
import ReqLiabiltyCover from '../../components/ReqLiabilityCover';

const LegalLiabiltyCover = () => {
    return(
        <div>
            <div classNameName=" d-flex align-items-center "
                data-secondary-overlay="8">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className=" text-center heroslider-content">
                                    <h2>LEGAL LIABILITY COVERS</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        <div className="container">
        <div className=" row ">
        <div className="col-lg-8 pr-0">
            <div class="service-details-content">
                <ol type="A" class="car-insurance mt-50">
                    <li>
                        <h5>GENERAL THIRD PARTY LIABILITY</h5>
                        <ul>
                            <li>
                                <h6>What is covered:</h6>
                                Payment of compensation for legal costs, expenses, court awards 
                                due to any negligent act of the insured persons leading to death, 
                                injury to another person(s) and/or loss or damage to property of 
                                another person(s)
                            </li>
                            <li>
                                <h6>What can be insured:</h6>
                                Personal and Corporate Activities leading to legal liabilities 
                                towards the insured’s neighbour, visitors, customers, and guests 
                                and the general public who may come in contact with the insured’s 
                                premises and operations.
                            </li>
                            <ReqLiabiltyCover />
                        </ul>
                    </li>
                    <li>
                        <h5>PROFESSIONAL INDEMNITY</h5>
                        <ul>
                            <li>
                                <h6>What is covered:</h6>
                                Payment of compensation for legal costs, expenses, court awards due 
                                to professional negligent of the insured persons as individual or 
                                group of individuals.
                            </li>
                            <li>
                                <h6>Who can be insured:</h6>
                                Professionals such as Doctors, Consultants, Nurses, Laboratory 
                                Technologists, Pharmacists, Architects, Quantity Surveryor, 
                                Engineers, Estate Agents, and Guilds as well as Associations. 
                                All those providing professional consultancy/advice whose action 
                                and inaction can lead to legal costs, expenses and court awards.
                            </li>
                            <ReqLiabiltyCover />
                        </ul>
                    </li>
                </ol>
            </div>
        </div>
        <div className="col-lg-3 pl-0 offset-lg-1">
            <Aside className="section-padding-bottom-xs"/>
        </div>
        </div>
            <Blockquote />
            
            <BusinessRiskForm />

        </div>
        </div>
    )
}

export default LegalLiabiltyCover;