export const insuranceOptions = [
  "Fire & Special Perils",
  "Burglary & Housebreaking (Theft)",
  "Money (Cash)",
  "Fidelity Guarantee",
  "Goods-In-Transit",
  "Plants All Risks",
  "Electronic/Computer All Risks.",
  "Householer/Houseowner Comprehensive",
  "Life Assurance",
  "Personal Accident",
  "Motor (Third Party)",
  "Motor (Third Party, Fire & Theft)",
  "Motor Comprehensive",
  "General Third Party Liability",
  "Professional Indemnity",
  "Product Liability",
  "Employer Liability",
  "Contractors All Risks",
  "Performance Bond",
  "Advance Payment Bond",
  "Credit Bond",
  "Marine Hull",
  "Marine (Cargo) Clause A",
  "Marine (Cargo) Clause C",
  ""
];