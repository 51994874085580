import React, { useRef, useState } from "react";
import Select from "../form-component/SelectCountry";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import axios from "axios";
import { insuranceOptions } from "./data";
import OtherForms from "../EmailPdf/HomeBusinessRiskFormEmail";

const BusinessRiskForm = () => {
  const [dropdownOpen, setDropdownOpen] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const targetRef = useRef(null);
  const otherFormRef = useRef(null);
  const [formData, setFormData] = useState({
    classStatus: "Corporate",
    name: "",
    address: "",
    streetAddress: "",
    addressLine2: "",
    city: "",
    country: "",
    localGovernmentArea: "",
    postalAddress: "",
    telephone: "",
    email: "",
    occupation: "",
    classOfInsurance: "",
    others: "",
    itemsToBeInsured: "",
    description: "",
    valueOfItems: "",
    annualCashMovements: "",
    maxCashCarried: "",
    maxCashInSafe: "",
    maxCashInPersonalPossession: "",
    annualLossDueToInfidelity: "",
    lossDueToInfidelity: "",
    employeesCovered: "",
    modeOfTransportation: "Road",
    ownershipOfTransport: "Owned",
    frequencyOfTransit: "Single Transit",
    limitOfLiability: "",
    benefitsDesired: "",
  });

  const apiUrl = "https://api.smoothwayinsurancebrokers.net/api/upload";

  const handleDropDown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePrinting = async (e) => {
    e.preventDefault();
    setIsUploading(true);

    try {
      // Ensure the element is in the DOM
      setTimeout(async () => {
        const input = otherFormRef.current;
        if (input) {
          // Capture the form as an image using html2canvas
          const canvas = await html2canvas(input);
          const imgData = canvas.toDataURL("image/png");

          // Create a new jsPDF instance
          const pdf = new jsPDF("p", "mm", "a4");
          const imgWidth = 210; // A4 width in mm
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          let position = 0;
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);

          // Save the PDF locally
          pdf.save("form-summary.pdf");

          // Prepare data to send to the server
          const pdfBlob = pdf.output("blob");
          const formDataWithPdf = new FormData();
          formDataWithPdf.append(
            "pdf_file",
            pdfBlob,
            `${formData.classOfInsurance}.pdf`
          );
          const response = await axios.post(apiUrl, formDataWithPdf, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          // Handle response status
          if (response.status !== 200) {
            throw new Error(`Server error: ${response.statusText}`);
          } else{
            setIsUploading(false);
            setFormData({
              classStatus: "Corporate",
              name: "",
              address: "",
              streetAddress: "",
              addressLine2: "",
              city: "",
              country: "",
              localGovernmentArea: "",
              postalAddress: "",
              telephone: "",
              email: "",
              occupation: "",
              classOfInsurance: "",
              others: "",
              itemsToBeInsured: "",
              description: "",
              valueOfItems: "",
              annualCashMovements: "",
              maxCashCarried: "",
              maxCashInSafe: "",
              maxCashInPersonalPossession: "",
              annualLossDueToInfidelity: "",
              lossDueToInfidelity: "",
              employeesCovered: "",
              modeOfTransportation: "Road",
              ownershipOfTransport: "Owned",
              frequencyOfTransit: "Single Transit",
              limitOfLiability: "",
              benefitsDesired: "",
            })
            alert('File sent Successfully')
          }
        } else {
          throw new Error("Element not found for html2canvas");
        }
      }, 0);
    } catch (error) {
      console.error("Error during print or upload process:", error);
      setIsUploading(false);
    }
  };

  return (
    <form className="form-horizontal col-sm-9 section-padding-bottom-md">
      <div className="row">
        <p className="col-sm-8 my-coll">
          {dropdownOpen
            ? "Click arrow to display form"
            : "Provide Necessary details"}
        </p>
        <div className="col-sm-4 my-col" onClick={handleDropDown}>
          {dropdownOpen ? (
            <MdKeyboardArrowDown size={30} />
          ) : (
            <MdKeyboardArrowUp size={30} />
          )}
        </div>
      </div>
      <div
        className={dropdownOpen ? "hide" : "show"}
        ref={targetRef}
        style={{ width: "90%", margin: "0 auto" }}
      >
        {!isUploading && (
          <>
            <div>
              <h3>Request for a Risk Check/Quotation</h3>
              <h5>Personal Information</h5>
            </div>
            <div className="form-group">
              <label className="control-label">Class/Status</label>
              <select
                name="classStatus"
                value={formData.classStatus}
                onChange={handleInputChange}
                className="form-control"
              >
                <option>Corporate</option>
                <option>Individual</option>
              </select>
            </div>
            <div className="form-group">
              <label className="control-label">Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label className="control-label">Address</label>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label className="control-label">Street Address</label>
              <input
                type="text"
                name="streetAddress"
                value={formData.streetAddress}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label className="control-label">Address Line 2</label>
              <input
                type="text"
                name="addressLine2"
                value={formData.addressLine2}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label className="control-label">City</label>
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label className="control-label">Country</label>
              <Select
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label className="control-label">Local Government Area</label>
              <input
                type="text"
                name="localGovernmentArea"
                value={formData.localGovernmentArea}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label className="control-label">Postal Address</label>
              <input
                type="text"
                name="postalAddress"
                value={formData.postalAddress}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label className="control-label">Telephone No(s)</label>
              <input
                type="phone"
                name="telephone"
                value={formData.telephone}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label className="control-label">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label className="control-label">Occupation/Business Type</label>
              <input
                type="text"
                name="occupation"
                value={formData.occupation}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div>
              <div>
                <h3>Risk Data/Vital Information</h3>
                <h5>
                  <hr />
                </h5>
              </div>
              <div className="form-group">
                <label>Class Of Insurance</label>
                <select
                  name="classOfInsurance"
                  value={formData.classOfInsurance}
                  onChange={handleInputChange}
                  id="input_2_11"
                  className="medium gfield_select"
                  tabIndex="15"
                  aria-invalid="false"
                >
                  {insuranceOptions.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label className="control-label">Others</label>
                <input
                  type="text"
                  name="others"
                  value={formData.others}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label className="control-label">Items to be insured</label>
                <textarea
                  type="text"
                  name="itemsToBeInsured"
                  value={formData.itemsToBeInsured}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label className="control-label">
                  Description (Make & Model)
                </label>
                <textarea
                  type="text"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label className="control-label">
                  Value of item(s) (Naira)
                </label>
                <textarea
                  type="text"
                  name="valueOfItems"
                  value={formData.valueOfItems}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label className="control-label">
                  Total Annual Cash/Currency Movements (Naira)
                </label>
                <input
                  type="number"
                  name="annualCashMovements"
                  value={formData.annualCashMovements}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label className="control-label">
                  Maximum Cash/Currency to be carried at a time (Naira)
                </label>
                <input
                  type="number"
                  name="maxCashCarried"
                  value={formData.maxCashCarried}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label className="control-label">
                  Maximum Cash to be kept in/out of safe at a time (Naira)
                </label>
                <input
                  type="number"
                  name="maxCashInSafe"
                  value={formData.maxCashInSafe}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label className="control-label">
                  Maximum Cash that can be in personal possession of named
                  person(s) or number of individuals to be covered (Naira)
                </label>
                <input
                  type="number"
                  name="maxCashInPersonalPossession"
                  value={formData.maxCashInPersonalPossession}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label className="control-label">
                  Annual Total loss due to insured Employees Infidelity (Naira)
                </label>
                <input
                  type="number"
                  name="annualLossDueToInfidelity"
                  value={formData.annualLossDueToInfidelity}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label className="control-label">
                  Loss due to the employee infidelity in one event (Naira)
                </label>
                <input
                  type="number"
                  name="lossDueToInfidelity"
                  value={formData.lossDueToInfidelity}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label className="control-label">
                  Name(s)/Position(s)/No of employees to be covered.
                </label>
                <textarea
                  type="number"
                  name="employeesCovered"
                  value={formData.employeesCovered}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label className="control-label">Mode of transportation</label>
                <select
                  name="modeOfTransportation"
                  value={formData.modeOfTransportation}
                  onChange={handleInputChange}
                  className="form-control"
                >
                  <option>Road</option>
                  <option>Air</option>
                  <option>Water</option>
                  <option>Rail</option>
                </select>
              </div>
              <div className="form-group">
                <label className="control-label">
                  Ownership of means of Transportation: (Owned or Hired)
                </label>
                <select
                  name="ownershipOfTransport"
                  value={formData.ownershipOfTransport}
                  onChange={handleInputChange}
                  className="form-control"
                >
                  <option>Owned</option>
                  <option>Hired</option>
                </select>
              </div>
              <div className="form-group">
                <label className="control-label">Frequency of transit</label>
                <select
                  name="frequencyOfTransit"
                  value={formData.frequencyOfTransit}
                  onChange={handleInputChange}
                  className="form-control"
                >
                  <option>Single Transit</option>
                  <option>Multiple Transit</option>
                </select>
              </div>
              <div className="form-group">
                <label className="control-label">
                  Limit of Liability to be paid in the event of legal action
                </label>
                <input
                  type="number"
                  name="limitOfLiability"
                  value={formData.limitOfLiability}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label className="control-label">
                  Benefit desired for death and permanent disability, temporary
                  disability, medical expenses, and burial expenses following an
                  accident to the insured person
                </label>
                <textarea
                  type="text"
                  name="benefitsDesired"
                  value={formData.benefitsDesired}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
            </div>
          </>
        )}
        {isUploading && (
          <div id="other-form" ref={otherFormRef}>
            <OtherForms formData={formData} />
          </div>
        )}
        <button className="in-button" type="button" disabled={isUploading} onClick={handlePrinting}>
          {isUploading ? "Submitting" : "Submit"}
        </button>
      </div>
    </form>
  );
};

export default BusinessRiskForm;
