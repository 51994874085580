import React, { Component } from "react";
// import BeneficiariesW from "./BeneficiariesW";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";

class MedicalHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doctorNameAddress: "",
      treatments: "",
      height: "",
      weight: "",
      gender: "Female", // default value
      alcoholConsumption: "",
      tobaccoConsumption: "",
      questions: {
        tuberculosis: "",
        indigestion: "",
        nervousDisease: "",
        kidneyInfection: "",
        fever: "",
        nightSweat: "",
        weightLoss: "",
        glandularInfection: "",
        diarrhoea: "",
        cough: "",
        hepatitisB: "",
        bloodDonor: "",
        bloodTransfusion: "",
        surgicalOperation: "",
        xray: "",
        ecg: ""
      },
      details: {
        indigestion: "",
        nervousDisease: "",
        kidneyInfection: "",
        fever: "",
        nightSweat: "",
        weightLoss: "",
        glandularInfection: "",
        diarrhoea: "",
        cough: "",
        hepatitisB: "",
        bloodDonor: "",
        bloodTransfusion: "",
        surgicalOperation: "",
        xray: "",
        ecg: ""
      }
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleRadioChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      questions: {
        ...prevState.questions,
        [name]: value
      }
    }));
  };

  handleDetailsChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      details: {
        ...prevState.details,
        [name]: value
      }
    }));
  };

  nextForm = (e) => {
    e.preventDefault();
    const {
      doctorNameAddress,
      treatments,
      height,
      weight,
      gender,
      alcoholConsumption,
      tobaccoConsumption,
      questions,
      details
    } = this.state;
    const data = {
      doctorNameAddress,
      treatments,
      height,
      weight,
      gender,
      alcoholConsumption,
      tobaccoConsumption,
      questions,
      details
    };

    this.props.onSubmit(data);
    this.props.nextStep();
  };

  prevForm = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const {
      doctorNameAddress,
      treatments,
      height,
      weight,
      gender,
      alcoholConsumption,
      tobaccoConsumption,
      questions,
      details
    } = this.state;

    const questionFields = [
      { label: "Tuberculosis, Asthma, Pneumonia or any other chest disease?", name: "tuberculosis" },
      { label: "Indigestion, Gastric or duodenal ulceration, Jaundice, Gall-bladder?", name: "indigestion" },
      { label: "Nervous disease or nervous breakdown, frequency headaches?", name: "nervousDisease" },
      { label: "Any infection of the kidney, urinary or genital organs, renal stones, difficult or painful urination, blood in urine?", name: "kidneyInfection" },
      { label: "Recurrent or persistent fever, skin disorder?", name: "fever" },
      { label: "Persistent Night Sweat?", name: "nightSweat" },
      { label: "Weight Loss?", name: "weightLoss" },
      { label: "Glandular infection or swollen glands?", name: "glandularInfection" },
      { label: "Chronic or Frequent Diarrhoea?", name: "diarrhoea" },
      { label: "Persistent Cough?", name: "cough" },
      { label: "Hepatitis B or any sexually transmitted disease including genital sores or discharges?", name: "hepatitisB" },
      { label: "Have you been refused as a blood donor?", name: "bloodDonor" },
      { label: "Have you ever received any blood transfusions within the last 5 years?", name: "bloodTransfusion" },
      { label: "Undergone any surgical operation?", name: "surgicalOperation" },
      { label: "Had an X-ray of the chest, stomach or any other organ?", name: "xray" },
      { label: "Had an electrocardiogram (ECG), blood studies or other special investigations or tests not mentioned above?", name: "ecg" }
    ];

    return (
      <div>
        <h4 className="form-heading">Medical History</h4>
        <div>
          <div className="form-group space-top-bottom">
            <label>Name and Address of your usual Medical Doctor</label>
            <input
              type="text"
              name="doctorNameAddress"
              value={doctorNameAddress}
              onChange={this.handleChange}
              placeholder="Separate name with hyphen(-)"
            />
          </div>
          <div className="form-group">
            <label>Details of treatments received in the past 5 years</label>
            <textarea
              name="treatments"
              value={treatments}
              onChange={this.handleChange}
              rows="4"
            />
          </div>
          <div className="row space-top-bottom">
            <div className="form-group col-sm-4">
              <label>Height (M)</label>
              <input
                type="text"
                name="height"
                value={height}
                onChange={this.handleChange}
                placeholder="e.g., 1.8m"
              />
            </div>
            <div className="col-sm-4">
              <label>Weight (kg)</label>
              <input
                type="text"
                name="weight"
                value={weight}
                onChange={this.handleChange}
                placeholder="e.g., 40kg"
              />
            </div>
            <div className="col-sm-4">
              <label>Gender</label>
              <select name="gender" value={gender} onChange={this.handleChange}>
                <option value="Female">Female</option>
                <option value="Male">Male</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <label>Daily Consumption of Alcohol</label>
              <input
                type="text"
                name="alcoholConsumption"
                value={alcoholConsumption}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-sm-4">
              <label>Daily Consumption of Tobacco</label>
              <input
                type="text"
                name="tobaccoConsumption"
                value={tobaccoConsumption}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="space-top-bottom">
            {questionFields.map((field) => (
              <div key={field.name} className="row space-top-bottom">
                <div className="marital-container col-sm-6">
                  <div className="marital-col">
                    <label>{field.label}</label>
                    <ul className="mar marital-status col-sm-2">
                      <input
                        type="radio"
                        name={field.name}
                        value="Yes"
                        checked={questions[field.name] === "Yes"}
                        onChange={this.handleRadioChange}
                      />
                      Yes
                    </ul>
                    <ul className="mar marital-status col-sm-2">
                      <input
                        type="radio"
                        name={field.name}
                        value="No"
                        checked={questions[field.name] === "No"}
                        onChange={this.handleRadioChange}
                      />
                      No
                    </ul>
                  </div>
                </div>
                {questions[field.name] === "Yes" && (
                  <div className="marital-container col-sm-6">
                    <div className="marital-col">
                      <label>If Yes, please give details. Date/Duration</label>
                      <input
                        type="text"
                        name={field.name}
                        value={details[field.name]}
                        onChange={this.handleDetailsChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-5">
            <button className="clap-next" onClick={this.nextForm}>
              Next <MdNavigateNext />
            </button>
          </div>
          <div className="col-sm-5">
            <button className="clap-next" onClick={this.prevForm}>
              <MdNavigateBefore /> Previous
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default MedicalHistory;
