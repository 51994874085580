import React from 'react';
import TeamMemberCard from '../components/TeamMemberCard';

const OurTeam = () => {
  const teamMembers = [
    {
      image: 'https://via.placeholder.com/150',
      name: 'Mr Olufunmilade Ayantuga',
      title: 'Chairman- Board of Directors',
      content: 'He graduated in Accounting from Howard University and holds MBA from George Washington University, USA. Left Central Bank of Nigeria as Deputy Director (Banking Supervision) and retired as Executive Director from Eko Int’l Bank (Now Skye Bank). Now operates in the Banking & Minning sector of the Nigerian economy.',
    },
    {
      image: 'https://via.placeholder.com/150',
      name: 'Mr Sesan Oluwasanya',
      title: 'Director',
      content: 'He is a fellow of both the Chartered Institute of Accountant of Nigeria and Chartered Institute of Taxation. Has worked in various multinational organizations. Now Directors and Chairman of some Nigerian companies like Commercial Properties Ltd; Goshen Dredging Ltd; Ditto Oil & Gas Ltd and Accord Savings & Loans Ltd..',
    },
    {
      image: 'https://via.placeholder.com/150',
      name: 'Mr Adekunle Osibodu',
      title: 'Director',
      content: 'He is a Chartered Accountant and an astute banker. Has occupied several executive/top management positions in Audit Firms, Banks, Insurance and Financial Institutions amongst which are Allstates Trust Bank; Linkage Assurance and Bond Bank. He is currently a Financial Consultant and Managing Director of Calbre Consulting Limited.',
    },
    {
      image: 'https://via.placeholder.com/150',
      name: 'Mr Harry Ogbeba',
      title: 'Director',
      content: 'He holds a BSc degree in Economics and MBA in Finance. A member of the Nigerian Institute of Management and Economic Society of Nigeria. In over 25 years he held several executive/top management positions in both Mining and Financial sectors of the Nigerian economy.',
    },
    {
      image: 'https://via.placeholder.com/150',
      name: 'Mrs Tinu Thompson-Ajayi ',
      title: 'Director',
      content: 'She graduated from Yaba College of Technology in Accountancy and hold MBA in Financial Management from Lagos State University. She has put in over 25 years at various levels in Banking and currently the chairperson of Association of Professional Women Bankers.',
    },
    {
      image: 'https://via.placeholder.com/150',
      name: 'Mr Rasaki Rabiu ',
      title: 'Director',
      content: 'He is a Chartered Accountant and a graduate of Accountancy from the University of Nigeria Nsukka. Holds a Masters in Business Administration (MBA) from the Lagos State University.  He retired as a Financial Director of General Motors (a division of UACN Apapa), and now as astute Finance Consultant.',
    },
    {
      image: 'https://via.placeholder.com/150',
      name: 'Mr Micheal Adewale Olunoiki ',
      title: 'Managing Director/CEO',
      content: 'He is a graduate of Insurance from the Lagos State Polytechnic. An Associate of the Chartered Insurance Institute of London and Nigeria. Has both local and international training and recorded about 30 years working experience in insurance broking.',
    },
  ];

  return (
    <div>
      {/* Breadcrumb */}
      <div className="d-flex align-items-center prem-bg" data-secondary-overlay="8">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="text-center heroslider-content">
                <h1>Our Directors</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Page Content */}
      <main className="page-content">
        <div className="services-area in-section section-padding-lg bg-shape">
          <div className="container">
            <div className="row">
              {teamMembers.map((member, index) => (
                <TeamMemberCard
                  key={index}
                  image={member.image}
                  name={member.name}
                  title={member.title}
                  content={member.content}
                />
              ))}
            </div>
          </div>
        </div>
        {/* Services Area */}
      </main>
      {/* Page Content */}
    </div>
  );
};

export default OurTeam;
