// OtherForms.js

import React from 'react';
import Logo from '../../assets/images/smoothway_logo.jpg'

const OtherForms = ({ formData }) => {
  return (
    <div className="email-container" id='other-form' style={{ width: '100%', margin: '0 auto', fontFamily: 'Arial, sans-serif', color: '#333' }}>
      <div className="email-header" style={{ textAlign: 'center', padding: '10px 0' }}>
        <img src={Logo} alt="Logo" style={{ maxWidth: '100px' }} />
      </div>
      <div className="email-body" style={{ padding: '5px', backgroundColor: '#f9f9f9' }}>
        <h6>Request for a Risk Check/Quotation</h6>
        <p>Personal Information</p>
        <ul className="grid-container" style={{ listStyle: 'none', padding: '0', display: 'flex', flexWrap: 'wrap' }}>
          <li className="grid-item" style={{ width: '50%', padding: '10px', boxSizing: 'border-box' }}><strong>Class/Status:</strong> {formData.classStatus}</li>
          <li className="grid-item" style={{ width: '50%', padding: '10px', boxSizing: 'border-box' }}><strong>Name:</strong> {formData.name}</li>
          <li className="grid-item" style={{ width: '50%', padding: '10px', boxSizing: 'border-box' }}><strong>Address:</strong> {formData.address}</li>
          <li className="grid-item" style={{ width: '50%', padding: '10px', boxSizing: 'border-box' }}><strong>Street Address:</strong> {formData.streetAddress}</li>
          <li className="grid-item" style={{ width: '50%', padding: '10px', boxSizing: 'border-box' }}><strong>Address Line 2:</strong> {formData.addressLine2}</li>
          <li className="grid-item" style={{ width: '50%', padding: '10px', boxSizing: 'border-box' }}><strong>City:</strong> {formData.city}</li>
          <li className="grid-item" style={{ width: '50%', padding: '10px', boxSizing: 'border-box' }}><strong>Country:</strong> {formData.country}</li>
          <li className="grid-item" style={{ width: '50%', padding: '10px', boxSizing: 'border-box' }}><strong>Local Government Area:</strong> {formData.localGovernmentArea}</li>
          <li className="grid-item" style={{ width: '50%', padding: '10px', boxSizing: 'border-box' }}><strong>Postal Address:</strong> {formData.postalAddress}</li>
          <li className="grid-item" style={{ width: '50%', padding: '10px', boxSizing: 'border-box' }}><strong>Telephone No(s):</strong> {formData.telephone}</li>
          <li className="grid-item" style={{ width: '50%', padding: '10px', boxSizing: 'border-box' }}><strong>Email:</strong> {formData.email}</li>
          <li className="grid-item" style={{ width: '50%', padding: '10px', boxSizing: 'border-box' }}><strong>Occupation/Business Type:</strong> {formData.occupation}</li>
        </ul>

        <p>Risk Data/Vital Information</p>
        <ul className="grid-container" style={{ listStyle: 'none', padding: '0', display: 'flex', flexWrap: 'wrap' }}>
          <li className="grid-item" style={{ width: '50%', padding: '10px', boxSizing: 'border-box' }}><strong>Class Of Insurance:</strong> {formData.classOfInsurance}</li>
          <li className="grid-item" style={{ width: '50%', padding: '10px', boxSizing: 'border-box' }}><strong>Others:</strong> {formData.others}</li>
          <li className="grid-item" style={{ width: '50%', padding: '10px', boxSizing: 'border-box' }}><strong>Items to be insured:</strong> {formData.itemsToBeInsured}</li>
          <li className="grid-item" style={{ width: '50%', padding: '10px', boxSizing: 'border-box' }}><strong>Description (Make & Model):</strong> {formData.description}</li>
          <li className="grid-item" style={{ width: '50%', padding: '10px', boxSizing: 'border-box' }}><strong>Value of item(s) (Naira):</strong> {formData.valueOfItems}</li>
          <li className="grid-item" style={{ width: '50%', padding: '10px', boxSizing: 'border-box' }}><strong>Total Annual Cash/Currency Movements (Naira):</strong> {formData.annualCashMovements}</li>
          <li className="grid-item" style={{ width: '50%', padding: '10px', boxSizing: 'border-box' }}><strong>Maximum Cash/Currency to be carried at a time (Naira):</strong> {formData.maxCashCarried}</li>
          <li className="grid-item" style={{ width: '50%', padding: '10px', boxSizing: 'border-box' }}><strong>Maximum Cash to be kept in/out of safe at a time (Naira):</strong> {formData.maxCashInSafe}</li>
          <li className="grid-item" style={{ width: '50%', padding: '10px', boxSizing: 'border-box' }}><strong>Maximum Cash that can be in personal possession of named person(s) or number of individuals to be covered (Naira):</strong> {formData.maxCashInPersonalPossession}</li>
          <li className="grid-item" style={{ width: '50%', padding: '10px', boxSizing: 'border-box' }}><strong>Annual Total loss due to insured Employees Infidelity (Naira):</strong> {formData.annualLossDueToInfidelity}</li>
          <li className="grid-item" style={{ width: '50%', padding: '10px', boxSizing: 'border-box' }}><strong>Loss due to the employee infidelity in one event (Naira):</strong> {formData.lossDueToInfidelity}</li>
          <li className="grid-item" style={{ width: '50%', padding: '10px', boxSizing: 'border-box' }}><strong>Name(s)/Position(s)/No of employees to be covered:</strong> {formData.employeesCovered}</li>
          <li className="grid-item" style={{ width: '50%', padding: '10px', boxSizing: 'border-box' }}><strong>Mode of transportation:</strong> {formData.modeOfTransportation}</li>
          <li className="grid-item" style={{ width: '50%', padding: '10px', boxSizing: 'border-box' }}><strong>Ownership of means of Transportation:</strong> {formData.ownershipOfTransport}</li>
          <li className="grid-item" style={{ width: '50%', padding: '10px', boxSizing: 'border-box' }}><strong>Frequency of transit:</strong> {formData.frequencyOfTransit}</li>
          <li className="grid-item" style={{ width: '50%', padding: '10px', boxSizing: 'border-box' }}><strong>Limit of Liability to be paid in the event of legal action:</strong> {formData.limitOfLiability}</li>
          <li className="grid-item" style={{ width: '50%', padding: '10px', boxSizing: 'border-box' }}><strong>Benefit desired for death and permanent disability, temporary disability, medical expenses, and burial expenses following an accident to the insured person:</strong> {formData.benefitsDesired}</li>
        </ul>
      </div>
      <div className="email-footer" style={{ textAlign: 'center', padding: '10px', backgroundColor: '#333', color: '#fff' }}>
        <p>Thank you for your request!</p>
      </div>
    </div>
  );
};

export default OtherForms;
