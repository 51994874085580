import React, { Component } from "react";
import Clapform from "../components/Clapform";
// steps dir
import DesiredBenefits from "./Desiredbenefits";
import OtherAssurance from "./OtherAssurance";
import PersonalData from "./Personaldata";
import MedicalHistory from "./MedicalHistory";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import FormSubmissionSummary from "../components/EmailPdf/FormSubmissionSummary";
import axios from "axios";

class MainForm extends Component {
  constructor() {
    super();
    this.state = {
      step: 1,
      isHidden: false,
      maritalStatus: "",
      personalData: {},
      desiredBenefits: {},
      medicalHistory: {},
      otherAssurance: {},
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.setState({
      maritalStatus: e.target.value,
    });
  }

  handlePersonalData = (data) => {
    this.setState({ personalData: data });
  };

  handleDesiredBenefits = (data) => {
    this.setState({ desiredBenefits: data });
  };

  handleMedicalHistory = (data) => {
    this.setState({ medicalHistory: data });
  };

  handleOtherAssurance = (data) => {
    this.setState({ otherAssurance: data }, () => {
      this.generatePDF();
    });
  };

  apiUrl = "https://api.smoothwayinsurancebrokers.net/api/upload";


  generatePDF = () => {
    const input = document.getElementById("form-summary");

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      const pdfBlob = pdf.output("blob");
      const formDataWithPdf = new FormData();
          formDataWithPdf.append(
            "pdf_file",
            pdfBlob,
            `${this.state.personalData?.firstName || 'Clap Form'}.pdf`
          );

          axios.post(this.apiUrl, formDataWithPdf, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then(res=>{console.log(res);alert('FIle sent successfully')}).catch(err=>{console.log(err);alert('File too large')})


      pdf.save(`${this.state.personalData?.firstName || 'Clap Form'}-CLAP.pdf`);
    });
  };

  isHidden = () => {
    this.setState({ isHidden: !this.isHidden });
  };

  nextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  };

  showStep = () => {
    const { step } = this.state;

    switch (step) {
      case 1:
        return (
          <PersonalData
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            onSubmit={this.handlePersonalData}
          />
        );
      case 2:
        return (
          <DesiredBenefits
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            onSubmit={this.handleDesiredBenefits}
          />
        );
      case 3:
        return (
          <MedicalHistory
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            onSubmit={this.handleMedicalHistory}
          />
        );
      case 4:
        return (
          <OtherAssurance
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            onSubmit={this.handleOtherAssurance}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const {
      step,
      personalData,
      desiredBenefits,
      medicalHistory,
      otherAssurance,
    } = this.state;
    const isHidden = this.state;
    return (
      <div>
        <Clapform />
        <form className="container clap-form-container">
          {isHidden ? step > 4 ? null : <h2>Step {step} of 4</h2> : null}
          {this.showStep()}
          {step === 5 && (
            <>
            <FormSubmissionSummary
              personalData={personalData}
              desiredBenefits={desiredBenefits}
              medicalHistory={medicalHistory}
              otherAssurance={otherAssurance}
            />
            <p>Do not close this page, as we are uploading your file...</p>
            </>
          )}
        </form>
      </div>
    );
  }
}

export default MainForm;
